import React, { FC, useEffect, useState } from 'react'
import { Collection, initialCollection } from '../core/_models';
import { Result } from '../../../../core/models/Result';
import { KTSVG, PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { useNavigate } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import { updateCollection } from '../core/_requests';
import { toast } from 'react-toastify';
import { camelize } from '../../../../core/utils/StringHelpers';
import { getPageList, getSectionList } from '../../banner/core/_requests';
import { Page, Section } from '../../banner/core/_models';
import clsx from 'clsx';
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect';
import { ProductGroup } from '../../../catalog/productGroup/productgroup-list/core/_models';
import { getProductGroupList } from '../../../catalog/productGroup/productgroup-list/core/_requests';
import { StatusOptions } from '../../../../core/models/StatusOptions';

type Props = {
    isBannerLoading: boolean;
    data: Result | Collection;
};
const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,

};
const CollectionEditPage: FC<Props> = ({ data, isBannerLoading }) => {
    const [pageList, setPageList] = useState<SelectOptionProps[]>([])
    const [sectionList, setSectionList] = useState<SelectOptionProps[]>([])

    const navigate = useNavigate()

    const collectionData = data as Collection
    const initialValues = {
        id: collectionData.id,
        name: collectionData.name,
        pageId: collectionData.pageId,
        sectionId: collectionData.sectionId,
        productGroupIds: collectionData.productGroupCollections?.map((item) => item.productGroupId),
        isActive: collectionData.isActive,
        statusId: (collectionData.isActive ? 1 : 0) || initialCollection.statusId
    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        //validationSchema: bannerSchema,

        onSubmit: async (formValues, { setSubmitting, setFieldError }) => {
             
            setSubmitting(true)
            let collectionData: Collection = {
                id: formValues.id,
                name: formValues.name,
                pageId: formValues.pageId,
                sectionId: formValues.sectionId,
                productGroupIds: formValues.productGroupIds as number[],
               // isActive:formValues.isActive
            }

            try {
                let result: Result;
                if (isNotEmpty(collectionData.id)) {
                    collectionData.isActive = formValues.statusId === 1
                    result = await updateCollection(collectionData)
                    if (result.hasOwnProperty('succeeded') && result?.succeeded) {
                        setSubmitting(true)
                        toast.success("Collection updated successfully.")
                        navigate('/cms/collection/list')

                    }
                    else {
                        if (result.statusCode === 400) {
                            result.propertyResults.map((error) =>
                                setFieldError(camelize(error.propertyName), error.errorMessage),
                                toast.error(result.exception)

                            )
                        }
                    }
                }

            } catch (ex) {
                console.error(ex)
                toast.error("Error caught during adding collection.")
            }
        },
    })

    useEffect(() => {
        let result: any

        getPageList(paginationFilter).then((v) => {
            result = v.data as Page[]
            let pageArray: any[] = []
            result.map((item: any) => {
                return pageArray.push({ value: item.id, label: item.name })
            })
            setPageList(pageArray)
        })

        getSectionList(paginationFilter).then((v) => {
            result = v.data as Section[]
            let sectionArray: any[] = []
            result.map((item: any) => {
                return sectionArray.push({ value: item.id, label: item.name })
            })
            setSectionList(sectionArray)
        })

    }, [])
    return (
        <FormikProvider value={formik}>
            <form
                id='banner_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate

            >
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0 cursor-pointer">

                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">Collection</h3>
                        </div>

                    </div>

                    <div id="settings_banner_details" className="collapse show">
                        <div className="card-body border-top p-9">

                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Collection Name
                                </label>

                                <div className="col-lg-8">
                                    

                                        <Field
                                            type='text'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.name && formik.errors.name },
                                                {
                                                    'is-valid': formik.touched.name && !formik.errors.name,
                                                }
                                            )}
                                            placeholder='Name'
                                            {...formik.getFieldProps('name')}
                                            name='name'
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.name}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />

                                   
                                </div>

                            </div>

                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Page
                                </label>

                                <div className="col-lg-8">
                                    
                                        <Field
                                            className='form-select-solid'
                                            component={CustomSelect}
                                            id="pageId"
                                            {...formik.getFieldProps('pageId')}
                                            name="pageId"
                                            options={pageList}
                                        >
                                        </Field>
                                        {formik.touched.pageId && formik.errors.pageId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.pageId}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />

                                </div>

                            </div>

                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Section
                                </label>
                                <div className="col-lg-8">
                                    
                                        <Field
                                            className='form-select-solid'
                                            component={CustomSelect}
                                            id="sectionId"
                                            {...formik.getFieldProps('sectionId')}
                                            name="sectionId"
                                            options={sectionList}
                                        >
                                        </Field>
                                        {formik.touched.sectionId && formik.errors.sectionId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.sectionId}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                                    
                                </div>

                            </div>

                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    ProductGroup
                                </label>
                                <div className="col-lg-8">
                                    
                                        <Field
                                            className='form-select-solid'
                                            component={CustomAsyncSelect<ProductGroup>}
                                            {...formik.getFieldProps(`productGroupIds`)}
                                            name={`productGroupIds`}
                                            loadOptionFunc={getProductGroupList}
                                            searchByField="name"
                                            labelField="name"
                                            isMulti={true}
                                            disabledisActiveFilter={true}
                                        // onChange={(newValue: any) => {  
                                        //                                                  
                                        //     formik.setFieldValue('productGroupIds', newValue?.value)
                                        // }}
                                        ></Field>

                                        {formik.touched.productGroupIds && formik.errors.productGroupIds && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.productGroupIds}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                                    
                                </div>

                            </div>



                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Status
                                </label>
                                <div className="col-lg-8">
                                    
                                        <Field
                                            className={clsx(
                                                'form-select-solid',
                                                { 'is-invalid': formik.touched.statusId && formik.errors.statusId },
                                                {
                                                    'is-valid': formik.touched.statusId && !formik.errors.statusId,
                                                }
                                            )}
                                            {...formik.getFieldProps('statusId')}

                                            name="statusId"
                                            options={StatusOptions}
                                            component={CustomSelect}
                                            placeholder="Select Status"
                                            isMulti={false}
                                        ></Field>
                                        {formik.touched.statusId && formik.errors.statusId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.statusId}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                                    
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                    <div className='d-flex justify-content-end'>
                                <button
                                    type='reset'
                                    className='btn btn-light me-3'
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_1"
                                    disabled={formik.isSubmitting}
                                >
                                    Discard
                                </button>
                                <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h2>Confirmation</h2>
                                                <div
                                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <KTSVG
                                                        path="/media/icons/duotune/arrows/arr061.svg"
                                                        className="svg-icon svg-icon-2x"
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-body">
                                                <div className="w-100">

                                                    {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
                                                    <i className="las la-exclamation-circle"></i>
                                                </div> */}

                                                    <div className="fv-row text-center">
                                                        <h5>Are you sure you want to discard ?</h5>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-light"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => navigate('/cms/collection/list ')}
                                                >
                                                    Yes
                                                </button>
                                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-color-modal-action='submit'
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                >
                                    <span className='indicator-label'>Submit</span>
                                    {(formik.isSubmitting) && (
                                        <span className='indicator-progress'>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                    </div>
                </div>
            </form>
        </FormikProvider>
    )
}

export default CollectionEditPage