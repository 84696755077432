import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { PurchaseByProductGroupStock, StockFilter } from './core/_model'
import { getPurchaseProductGroupStock } from './core/_request'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
import { useNavigate } from 'react-router-dom'

function PurchaseByProductGroup() {
  const [salesStock, setSalesStock] = useState<PurchaseByProductGroupStock[]>([])
  const [filters, setFilters] = useState<StockFilter>({})
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [filterOrderDateFrom, setFilterOrderDateFrom] = useState<Date | string | undefined>(undefined);
  const [filterOrderDateTo, setFilterOrderDateTo] = useState<Date | string | undefined>(undefined);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<PurchaseByProductGroupStock[]>(salesStock);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPurchaseProductGroupStock(filters);
        if (Array.isArray(data)) {
          setSalesStock(data as PurchaseByProductGroupStock[]);
        } else {
          console.error('Fetched data is not an array:', data);
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    const newFilteredData = salesStock.filter((item: PurchaseByProductGroupStock) =>
      Object.values(item).some((value: string | number) =>
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    );
    setFilteredData(newFilteredData);
  }, [salesStock, globalFilterValue]);

  const formatCurrency = (amount: number, locale: string = 'en-IN', currency: string = 'INR'): string => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(amount);
  };

  const handleDateChange = (selectedDates: Date[], dateName: string) => {
    if (dateName === 'startDate') {
      if (selectedDates[0] === undefined) {
        setFilterOrderDateFrom('')
      }
      else {

        setFilterOrderDateFrom(selectedDates[0]?.toDateString());
      }

    } else if (dateName === 'endDate') {
      if (selectedDates[0] === undefined) {
        setFilterOrderDateTo('')
      }
      else {

        setFilterOrderDateTo(selectedDates[0]?.toDateString());
      }
    }

  };


  const updateSearchFilters = () => {
    const filter: StockFilter = {};
    if (filterOrderDateFrom) {
      const startDate = toZonedTime(new Date(filterOrderDateFrom as Date), 'Asia/Kolkata');
      filter.startDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
    }

    if (filterOrderDateTo) {
      const endDate = toZonedTime(new Date(filterOrderDateTo as Date), 'Asia/Kolkata');
      filter.endDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
    }

    // const newFilteredData = salesStock.filter((item: SalesByAgentStock) =>
    //   Object.values(item).some((value: string | number) =>
    //     typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
    //   )
    // );
    // setFilteredData(newFilteredData);
    setFilters(filter);
  };


  return (
    <><div className="card mb-7">
      <div className="card-header justify-content-between align-items-center">
        <div className="card-title">
          <h3>Purchase By Product Group FIlter</h3>
        </div>
        <button
          className="btn btn-light btn-active-light-primary border"
          onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
        >
          <i className="ki-duotone ki-filter fs-1 text-muted me-1"><span className="path1"></span><span className="path2"></span></i>
        </button>
      </div>
      {advancedSearchVisible && (
          <>
      <div className="card-body">
        {/* <div className="row mb-7">
          <div className="col-lg-6">
            <div className="input-group">
              <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>

              <input
                type="text"
                className="form-control form-control-solid ps-10"
                id="txtsearchText"
                placeholder="Search Product Group"
                value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)}
              />
              <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}
              >
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <button
              className="btn btn-link"
              onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
            >
              Advanced Search
            </button>
          </div>
          <div className='col-lg-2 '>
            <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>Back

            </button>
          </div>
        </div> */}
       
            <div className="collapse" id="kt_advanced_search_form">
              <div className="separator separator-dashed mt-9 mb-6"></div>
            </div>



            <div className="col-lg-4">
              <label className="fs-6 form-label fw-bold text-dark">
                Date
              </label>
              <div className="row g-3">
                <div className="col">
                  <Flatpickr
                    //  value={dateState.date1}
                    options={{ dateFormat: 'Y-m-d' }}
                    onChange={(selectedDates) => handleDateChange(selectedDates, 'startDate')}
                    className="form-control form-control-solid"
                    placeholder="From"

                  />
                </div>
                <div className="col">
                  <Flatpickr
                    // value={dateState.date2}
                    options={{ dateFormat: 'Y-m-d' }}
                    onChange={(selectedDates) => handleDateChange(selectedDates, 'endDate')}
                    className="form-control form-control-solid"
                    placeholder="To"

                  />
                </div>
              </div>
            </div>



         
      </div>
      <div className="card-footer text-end">
      <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}
              >
                Search
              </button>
      </div>
      </>
        )}
    </div> 
    
    <KTCard>
      <div className="card-header justify-content-between align-items-center">
        <div className="card-title">
          <h3>Purchase By Product Group</h3>
        </div>
          <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>Back

          </button>
      </div>
        <KTCardBody>
          <>
            {/* <div className='card-header border-0 pt-6 '>
              <div className='surface-section px-4 py-5 md:px-6 lg:px-8'>
                <div className='flex flex-column md:align-items-center md:justify-content-between md:flex-row'>
                  <div className='h2'>Sales By Customer</div>
                </div>
              </div>
            </div> */}
            <div>
              <DataTable
                className='table align-middle fw-bolder table-row-dashed fs-5 text-uppercase gy-5 dataTable no-footer '
                value={filteredData}
                showGridlines
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
              >
                <Column
                  className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  field='productGroupName'
                  header='Product Group '
                  sortable
                  style={{ minWidth: '6rem' }} />
                <Column
                  className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  field='designNumber'
                  header='Design Number'
                  sortable />
                <Column
                  className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  field='categoryName'
                  header='Category'
                  sortable />
                <Column
                  className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  field='manufacturerName'
                  header='Manufacturer'
                  sortable />

                <Column
                  className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  field='brandName'
                  header='Brand'
                  sortable />
                <Column
                  className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  field='quantity'
                  header='Quantity'
                  sortable />
                <Column
                  className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  field='totalAmount'
                  header='Total Amount'
                  sortable
                  body={(rowData) => formatCurrency(rowData.totalAmount as number)} />



              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard></>
  )
}

export default PurchaseByProductGroup
