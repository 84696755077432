import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../_metronic/helpers/crud-helper/models'
import { Result } from '../../../core/models/Result'
import { AutoNumberGenerationGroupDto, CreditNoteSetting, CustomerVendor, EmailSetting, GSTSetting, InvoiceSetting, OrganizationProfile, PurchaseOrderSetting, SalesOrderSetting } from './_models'

const API_URL = process.env.REACT_APP_STORE_API_URL
const SalesOrderSetting_EDIT_URL = `${API_URL}/salesordersetting`
const AUTONUMBERGENERATION_GET_URL = `${API_URL}/autonumbergeneration`
const AUTONUMBERGENERATION_UPDATE_URL = `${API_URL}/autonumbergeneration`
const CREDIT_NOTE_SETTING_BY_ID_URL = `${API_URL}/creditnotesetting`
const CUSTOMERVENDOR_BY_ID_URL = `${API_URL}/customervendorsetting`
const EMAILSETTING_BY_ID_URL = `${API_URL}/emailsetting`
const PurchaseOrderSetting_EDIT_URL = `${API_URL}/purchaseordersetting`
const InvoiceSetting_EDIT_URL = `${API_URL}/invoicesetting`
const OrganizationProfile_EDIT_URL = `${API_URL}/organizationprofile`
const gstsetting_BY_ID_URL = `${API_URL}/gstsetting`



const updateSalesOrderSetting = async (soSetting: SalesOrderSetting): Promise<Result> => {
  return await axios
    .put(`${SalesOrderSetting_EDIT_URL}/${soSetting.id}`, {
      id: soSetting.id,
      termCondition: soSetting.termCondition,
      customerNotes: soSetting.customerNotes,
      isClosedOrderEditable: soSetting.isClosedOrderEditable,
      defaultDeliveryTime: soSetting.defaultDeliveryTime,
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getSalesOrderSetting = async (): Promise<Result | SalesOrderSetting> => {
  return await axios
    .get(`${SalesOrderSetting_EDIT_URL}`)
    .then((response: AxiosResponse<SalesOrderSetting>) => response.data)
    .then((response: SalesOrderSetting) => response)
    .catch((err: Result) => {
      return err
    })
}

const updatePurchaseOrderSetting = async (poSetting: PurchaseOrderSetting): Promise<Result> => {
  return await axios
    .put(`${PurchaseOrderSetting_EDIT_URL}/${poSetting.id}`, {
      id: poSetting.id,
      termsCondition: poSetting.termsCondition,
      notes: poSetting.notes,      
      defaultDeliveryTime: poSetting.defaultDeliveryTime,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getPurchaseOrderSetting = async (): Promise<Result | PurchaseOrderSetting> => {
  return await axios
    .get(`${PurchaseOrderSetting_EDIT_URL}`)
    .then((response: AxiosResponse<PurchaseOrderSetting>) =>{
        return response.data})
    .then((response: PurchaseOrderSetting) => response)
    .catch((err: Result) => {
      return err
    })
}

const getAutoNumberGeneration = async (): Promise<Result | AutoNumberGenerationGroupDto> => {
  return await axios
    .get(`${AUTONUMBERGENERATION_GET_URL}`)
    .then((d: AxiosResponse<AutoNumberGenerationGroupDto>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const updateAutoNumberGeneration = (
  autonumbergeneration: AutoNumberGenerationGroupDto
): Promise<Result> => {

  return axios
    .put(`${AUTONUMBERGENERATION_UPDATE_URL}/${autonumbergeneration.id}`, {
      id: autonumbergeneration.id,
      name: autonumbergeneration.name,
      isDefault: autonumbergeneration.isDefault,
      autoNumberGenerationDtos: autonumbergeneration.autoNumberGenerationDtos,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getCreditNote = async (): Promise<Result | CreditNoteSetting> => {
  return await axios
    .get(`${CREDIT_NOTE_SETTING_BY_ID_URL}`)
    .then((d: AxiosResponse<CreditNoteSetting>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const updateCreditNote = (
  creditNote: CreditNoteSetting
): Promise<Result> => {

  return axios
    .put(`${CREDIT_NOTE_SETTING_BY_ID_URL}/${creditNote.id}`, {
      id: creditNote.id,
      termsCondition: creditNote.termsCondition,
      customerNotes: creditNote.customerNotes
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const getCustomerVendor = async (): Promise<Result | CustomerVendor> => {
  return await axios
    .get(`${CUSTOMERVENDOR_BY_ID_URL}`)
    .then((d: AxiosResponse<CustomerVendor>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const updateCustomerVendor = (
  customerDetail: CustomerVendor
): Promise<Result> => {

  return axios
    .put(`${CUSTOMERVENDOR_BY_ID_URL}/${customerDetail.id}`, {
      id: customerDetail.id,
      isDuplicateCustomerVendorName: customerDetail.isDuplicateCustomerVendorName,
      defaultCustomerType: customerDetail.defaultCustomerType,
      customerVendorBillingFormat: customerDetail.customerVendorBillingFormat,
      customerVendorShippingFormat: customerDetail.customerVendorShippingFormat,
      defaultCustomerId:customerDetail.defaultCustomerId
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const getEmailSetting = async (): Promise<Result | EmailSetting> => {
  return await axios
    .get(`${EMAILSETTING_BY_ID_URL}`)
    .then((d: AxiosResponse<EmailSetting>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const updateEmailSetting= (
  email: EmailSetting
): Promise<Result> => {

  return axios
    .put(`${EMAILSETTING_BY_ID_URL}/${email.id}`, {
      id: email.id,
      emailEncryption: email.emailEncryption,
      smtpHost:email.smtpHost ,
      smtpPort:email.smtpPort,
      smtpUserName: email.smtpUserName,
      smtpPassword: email.smtpPassword,
      emailAddress: email.emailAddress,
      emailCharset: email.emailCharset,
      bccAllEmailsTo: email.bccAllEmailsTo,
      emailSignature: email.emailSignature,
      predefinedHeader: email.predefinedHeader,
      predefinedFooter: email.predefinedFooter,
      enableEmailQueue: email.enableEmailQueue,
      allowEmailWithAttachmentInQueue: email.allowEmailWithAttachmentInQueue
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}


const getInvoiveSetting = async (): Promise<Result | InvoiceSetting> => {
  return await axios
    .get(`${InvoiceSetting_EDIT_URL}`)
    .then((d: AxiosResponse<InvoiceSetting>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const updateInvoiceSetting= (
  invoice: InvoiceSetting
): Promise<Result> => {

  return axios
    .put(`${InvoiceSetting_EDIT_URL}/${invoice.id}`, {
      id: invoice.id,
      termCondition: invoice.termCondition,
      customerNotes: invoice.customerNotes,
      isEditAllowedSentInvoice:invoice.isEditAllowedSentInvoice
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getOrganizatinProfile= async (): Promise<Result | OrganizationProfile> => {
  return await axios
    .get(`${OrganizationProfile_EDIT_URL}`)
    .then((d: AxiosResponse<OrganizationProfile>) => {
      console.log("in req",d.data)
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const updateOrganizatinProfile= (
  profile: OrganizationProfile
): Promise<Result> => {

  return axios
    .put(`${OrganizationProfile_EDIT_URL}`, {
    id:profile.id,
    organizationName:profile.organizationName,
    location:profile.location,
    phoneNumber:profile.phoneNumber,
    fax:profile.fax,
    website:profile.website,
    reportBasis:profile.reportBasis,
    languageId:profile.languageId,
    languageName:profile.languageName,
    timeZoneId:profile.timeZoneId,
    timeZoneName:profile.timeZoneName,
    dateFormatId:profile.dateFormatId,
    dateFormatName:profile.dateFormatName ,
    updateLogoImage:profile.updateLogoImage,
    firstName:profile.firstName,
    lastName:profile.lastName,
    emailAddress:profile.emailAddress,
    mobileNumber:profile.mobileNumber,
    address:profile.address,
    isEnableDiscountForWebCustomer: profile.isEnableDiscountForWebCustomer,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getGSTById = async (): Promise<Result | GSTSetting> => {
  return await axios
    .get(`${gstsetting_BY_ID_URL}`)
    .then((response: AxiosResponse<GSTSetting>) => response.data)
    .then((response: GSTSetting) => response)
    .catch((err: Result) => {
      return err
    })
}
const updateGST = async (gstSetting: GSTSetting): Promise<Result> => {
  return await axios
    .put(`${gstsetting_BY_ID_URL}`, {
      id: gstSetting.id,
      isBusinessRegisterGST: gstSetting.isBusinessRegisterGST,
      gstin: gstSetting.gstin,
      businessLegalName: gstSetting.businessLegalName,
      businessTradeName: gstSetting.businessTradeName,
      gstRegisteredOn: gstSetting.gstRegisteredOn,
      compositionScheme: gstSetting.compositionScheme,
      reverseCharge: gstSetting.reverseCharge,
      importExport: gstSetting.importExport,
      placeOfSupplyId:gstSetting.placeOfSupplyId
     

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
export {
  updateSalesOrderSetting,
  getSalesOrderSetting,
  getAutoNumberGeneration,
  updateAutoNumberGeneration,
  getCreditNote,
  updateCreditNote,
  getCustomerVendor,
  updateCustomerVendor,
  getEmailSetting,
  updateEmailSetting,
  updatePurchaseOrderSetting,
  getPurchaseOrderSetting,
  getInvoiveSetting,
  updateInvoiceSetting,
  getOrganizatinProfile,
  updateOrganizatinProfile,
  getGSTById,
  updateGST
}
