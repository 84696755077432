import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik, validateYupSchema } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Toast } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import {  Role, initialRole } from '../core/_models'
import { Result } from '../../../../core/models/Result'
import { PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers'
import { camelize } from '../../../../core/utils/StringHelpers'
import { createRole, updateRole } from '../core/_requests'

import { Loading } from '../../../../core/shared/components/Loading'
import { StatusOptions } from '../../../../core/models/StatusOptions'
import CustomSelect from '../../../../core/shared/components/CustomSelect'

// import { AccountGroup } from '../../account-group/core/_models'
type Props = {
  isRoleLoading: boolean
  data: Role | Result
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,

}

const roleSchema = Yup.object().shape({
  name: Yup.string().required('Please enter role Name.'), 
  
  
})

const RoleForm: FC<Props> = ({ data, isRoleLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  let role = data as Role;
  const initialValues:Role = {
    id: role.id,
    name: role.name,    
    statusId: (role.isActive ? 1 : 0) || 0  
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: roleSchema,

    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        
        let result: Result
        formValues.isActive = formValues.statusId == 1
        if (isNotEmpty(formValues.id )) {

          result = await updateRole(formValues)
        } else {
          result = await createRole(formValues)
        }
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Role created/updated successfully');

          cancel(true)
        }
        else {
          toast.error('Error Occurred while updating Role.');
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
        }
      } catch (ex) {
        toast.error('Error Occurred .');
        console.error(ex)
      }
    },
  })
    

  return (

    <FormikProvider value={formik}>
    
      <form
        id='kt_modal_add_role_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column'
          id='kt_modal_add_role_scroll'
          data-kt-scroll='true'
        >     
          <div className="row mb-6">
            <label className="col-form-label fw-semibold fs-6">
              Role Name
            </label>

            <div className="fv-row fv-plugins-icon-container">
              <input
                placeholder='Role Name'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.name && formik.errors.name },
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRoleLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
       
          {itemIdForUpdate !== null ?
            <>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2 ms-1'>Status</label>
                <Field
                  className={clsx(
                    'form-select-solid ms-1',
                    { 'is-invalid': formik.touched.statusId && formik.errors.statusId },
                    {
                      'is-valid': formik.touched.statusId && !formik.errors.statusId,
                    }
                  )}
                  {...formik.getFieldProps('statusId')}

                  name="statusId"
                  options={StatusOptions}
                  component={CustomSelect}
                  placeholder="Select Status"
                  isMulti={false}
                ></Field>
              </div>

            </> : ''}

          <div className='text-end pt-5'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-regions-modal-action='cancel'
              disabled={formik.isSubmitting || isRoleLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-regions-modal-action='submit'
              disabled={isRoleLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isRoleLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isRoleLoading) && <Loading />}
    </FormikProvider >
  )
}

export { RoleForm }
