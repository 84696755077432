import {KTCard} from '../../../../_metronic/helpers'
import PaymentAdvancedSearch from './components/header/header/PaymentAdvancedSearch'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PaymentTable} from './payment-table/PaymentTable'


const PaymentList = () => {
  return (
    <>
        <PaymentAdvancedSearch/>
      <KTCard>
       {/* <PaymentListHeader/> */}
        <PaymentTable />
      </KTCard>
   
    </>
  )
}

const PaymentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PaymentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PaymentListWrapper}
