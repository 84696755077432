import React, { FC, useEffect } from 'react'
import { Result } from '../../../../core/models/Result';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Expense } from '../core/_models';
import { formatDate } from '../../../../core/models/DateFormatter';
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter';


type Props = {

    data: Result | Expense;
};

const ExpenseDetailForm: FC<Props> = ({ data, }) => {
    const expense = data as Expense

    return (

        <div className="card pt-4 mb-2 mb-xl-9">

                <div className="card-header border-0">
                    <div className="card-title">
                        <h2>Expense Details</h2>
                    </div>

                </div>

                <div className="card-body pt-0 pb-5">
                        <div className="separator separator-dashed my-3" />
                        <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-3 fv-plugins-icon-container">
                                <label className="fs-5 fw-bold">{expense.vendorId === null ? 'Staff Name' : 'Vendor Name'} :
                                    <span className='fs-6 fw-semibold'>   {expense?.vendorId === null ? expense?.staffName : expense?.vendorName}</span>
                                </label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="fv-row mb-3 fv-plugins-icon-container">
                                <label className="fs-5 fw-bold">Expense Category :
                                    <span className='fs-6 fw-semibold'>  {expense?.expenseCategory}</span>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-3 fv-plugins-icon-container">
                                <label className="fs-5 fw-bold">Expense Date :
                                    <span className='fs-6 fw-semibold'> {formatDate(expense?.expenseDate, 'dd MMM yyyy')}</span>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                <label className="fs-5 fw-bold">Expense Amount :
                                    <span className='fs-6 fw-semibold'> {formatCurrencyInINR(expense?.expenseAmount)}</span>
                                </label>
                            </div>
                        </div>
                        </div>
                     
                    </div>
                </div>



      

    )
}

export default ExpenseDetailForm