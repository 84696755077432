import { FC, useEffect, useState, } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { KTCard, KTCardBody, PaginationFilter } from '../../../../_metronic/helpers'
import { useQueryResponse } from './core/QueryResponseProvider'
import { SelectOptionProps } from '../../../core/models/SelectOptionProps'
import { useListView } from './core/ListViewProvider'
import { Result } from '../../../core/models/Result'
import { SaleOrderPayment } from '../salesOrder/salesOrder-list/core/_models'
import { createSOPayment } from '../salesOrder/salesOrder-list/core/_requests'
import { camelize } from '../../../core/utils/StringHelpers'
import { getPaymentModeList } from '../../settings/paymode/core/_requests'
import { PaymentMode } from '../../settings/paymode/core/_models'
import { getCustomerList } from '../customer/customer-list/core/_requests'
import { Customer } from '../customer/customer-list/core/_models'
import CustomDatePicker from '../../../core/shared/components/CustomDatePicker'
import CustomSelect from '../../../core/shared/components/CustomSelect'
import { Loading } from '../../../core/shared/components/Loading'
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
import Flatpickr from 'react-flatpickr';
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const paymentSchema = Yup.object().shape({
  amountReceived: Yup.number().required('Please enter amount.').min(0, 'Amount should be positive value'),
  paymentDate: Yup.string().required("Please enter Payment date"),
  paymentModeId: Yup.string().required('Please select payment mode.'),
  referenceNumber: Yup.string().required("Please enter the reference Number"),
  notes: Yup.string(),
  bankName: Yup.string(),
  chequeNumber: Yup.number(),
  chequeDate: Yup.date(),
  customerId: Yup.number().required('Please select the customer')

})

const PaymentForm: FC = ({ }) => {
  const { refetch } = useQueryResponse()
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const [PaymentModeOptions, setPaymentModeOptions] = useState<SelectOptionProps[]>([])
  const [customer, setCustomer] = useState<SelectOptionProps[]>([])
  const today = new Date();
  const minDate = new Date('1900-01-01');
  const initialValues = {
    customerId: undefined,
    paymentDate: new Date(),
    amountReceived: 0,
    paymentModeId: undefined,
    bankName: '',
    chequeNumber: 0,
    chequeDate: new Date(),
    referenceNumber: '',
    notes: '',
    isAdvance: true
  }



  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paymentSchema,
    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {

      setSubmitting(true)
      try {
        if (formValues?.paymentDate as Date > today || (formValues?.paymentDate as Date) < minDate) {
          formik.setFieldError('paymentDate', 'Payment date cannot be later than current date')
          return
        }
        const payDate = toZonedTime(formValues.paymentDate as Date, 'Asia/Kolkata');
        let result: Result
        let payment: SaleOrderPayment = {
          amountReceived: formValues.amountReceived,
          paymentDate: format(payDate, 'yyyy-MM-dd'),
          paymentModeId: formValues.paymentModeId,
          referenceNumber: formValues.referenceNumber,
          notes: formValues.notes,
          customerId: formValues.customerId,
          isAdvance: true,

        }

        result = await createSOPayment(payment) as Result
        if (result.succeeded === true) {
          setSubmitting(true)
          toast.success('Payment added successfully')
          navigate("/sales/payment/list")
        }


        else if (result.succeeded === false) {
          toast.error(result.messages[0])
        }

        else if (result.statusCode === 400) {
          result.propertyResults.forEach((error) =>
            setFieldError(camelize(error.propertyName), error.errorMessage)
          );


        }
      } catch (ex) {
        toast.error("error")
      }
    },
  })


  useEffect(() => {
    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },
    }

    getPaymentModeList(updatedPaginationFilter).then((data) => {
      let result = data.data as PaymentMode[]
      let paymentModeArray: any[] = []
      result.map((item: any) => {
        return paymentModeArray.push({ value: item.id, label: item.name })
      })
      setPaymentModeOptions(paymentModeArray)
    })

    getCustomerList(paginationFilter).then((data) => {
      let result = data.data as Customer[]
      let customerArray: any[] = []
      result.map((item: any) => {
        return customerArray.push({ value: item.id, label: item.printName })
      })
      setCustomer(customerArray)
    })

  }, [])
  return (
    <FormikProvider value={formik}>
      <KTCard>
        <div className="card-header">
          <div className="card-title">
            <h3>Add Payment</h3>
          </div>
        </div>
        <KTCardBody>

          <form
            id='kt_modal_add_status_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div
              className='d-flex flex-column'
              id='kt_modal_add_status_scroll'

            >
              <div className='row mb-7'>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Customer</label>
                  <Field
                    className='form-select-solid'
                    options={customer}
                    component={CustomSelect}
                    name={'customerId'}
                    placeholder={'select customer'}
                  >
                  </Field>
                  {formik.touched.customerId && formik.errors.customerId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.customerId}</span>
                      </div>
                    </div>
                  )}

                </div>
              </div>
              <div className='row mb-7'>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Amount</label>
                  <Field
                    type='number'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('amountReceived')}
                    name='amountReceived'
                    onChange={(e: any) => {
                      //formik.handleChange(e)
                      formik.setFieldValue('amountReceived', e.target.value)
                    }
                    }
                  />
                  {formik.touched.amountReceived && formik.errors.amountReceived && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.amountReceived}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Payment Date</label>
                  <div>
                    <div>
                      <Flatpickr
                        className='form-control'
                        placeholder='Pick date'
                        {...formik.getFieldProps('paymentDate')}
                        name={'paymentDate'}
                        onChange={([date1]) => {
                          formik.setFieldValue('paymentDate', date1)
                        }}

                      />
                    </div>
                    {formik.touched.paymentDate && formik.errors.paymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.paymentDate as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-7'>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Reference Number</label>
                  <Field
                    type='text'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('referenceNumber')}
                    name='referenceNumber'
                    onChange={(e: any) => {
                      formik.handleChange(e)
                      // onQuantityChange( e.target.value)
                    }
                    }
                  />
                  {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.referenceNumber}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Notes</label>

                <textarea
                  rows={3}
                  placeholder='notes'
                  {...formik.getFieldProps('notes')}

                  name='notes'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1',
                    { 'is-invalid': formik.touched.notes && formik.errors.notes },
                    {
                      'is-valid': formik.touched.notes && !formik.errors.notes,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.notes}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row mb-7'>
                <label className="form-label required">Payment Mode</label>
                <div className='d-flex'>
                  {PaymentModeOptions.map((option) => (
                    <div key={option.value} className='form-check form-check-inline'>
                      <Field
                        type='radio'
                        className='form-check-input'
                        name='paymentModeId'
                        value={option.value}
                        checked={formik.values.paymentModeId === option.value}
                        onChange={() => formik.setFieldValue('paymentModeId', option.value)}
                      />
                      <label className='form-check-label'>{option.label}</label>
                    </div>
                  ))}
                </div>
              </div>
              {formik.values.paymentModeId === 2 && (
                <div className='row mb-7'>
                  <div className='col-4 mb-3'>
                    <label className='form-label required'>Bank Name</label>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('bankName')}
                      name='bankName'
                      onChange={(e: any) => {
                        formik.handleChange(e)
                        // onQuantityChange( e.target.value)
                      }
                      }
                    />
                  </div>

                  <div className='col-4 mb-3'>
                    <label className='form-label required'>Cheque Number</label>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('chequeNumber')}
                      name='chequeNumber'
                      onChange={(e: any) => {
                        formik.handleChange(e)
                        // onQuantityChange( e.target.value)
                      }
                      }
                    />
                  </div>
                  <div className='col-4 mb-3'>
                    <label className='form-label required'>Cheque Date</label>
                    <div>
                      <CustomDatePicker
                        name='chequeDate'
                      />
                    </div>
                  </div>

                </div>
              )}
              <div className='text-right pt-5'>
                <button
                  type='reset'
                  onClick={() => { navigate("/sales/payment/list") }}
                  className='btn btn-light me-3 border'
                  data-kt-status-modal-action='cancel'
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-status-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
          {(formik.isSubmitting) && <Loading />}
        </KTCardBody>
      </KTCard>
    </FormikProvider >
  )
}

export { PaymentForm }
