import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import { useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from '../core/QueryResponseProvider'
import { Agent } from '../core/_models'
import { AgentColumns } from './columns'
import { CustomHeaderColumn } from './CustomHeaderColumn'
import { CustomRow } from './CustomRow'
import { KTCardBody } from '../../../../../_metronic/helpers'
import Pagination from '../../../../core/shared/components/Pagination'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { AgentListToolbar } from '../components/header/AgentListToolbar'
//import { Loading } from '../../../../core/shared/components/Loading'


const AgentTable = () => {
  const pagination = useQueryResponsePagination();
  const { updateState } = useQueryRequest();
  const agent = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => agent, [agent])
  const columns = useMemo(() => AgentColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
  <>
  <div className='card-header'>
    <div className='card-title justify-content-between w-100'>
      <h3>Agent</h3>
      <AgentListToolbar/>
    </div>
  </div>
      <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_agent'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Agent>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Agent>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination pagination={pagination} isLoading={isLoading} updateState={updateState} />

      {/* {isLoading && <Loading />} */}
    </KTCardBody>
  </>

  )
}

export {AgentTable}
