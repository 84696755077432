import { ID, Response } from "../../../../../_metronic/helpers"

export type Agent = {
  id?: ID
  agentCode?: string
  firstName?: string
  lastName?: string
  companyName?: string
  emailAddress?: string
  mobileNumber?: number
  whatsappNumber?: number
  addressLine1?: string
  addressLine2?: string
  city?: string
  stateId?: number
  zipCode?: string
  commissionPercent?: number
  dateOfBirth?: Date | string
  dateOfAnniversary?: Date | string

  isActive?: boolean
  rateId?: number
  enableLogin?: boolean
  userName?: string
  password?: string
  statusId?: number
  stateName?: string
  userId?: string
  uploadImage?: agentImage
  imagePath?: string
  agentImagePath?: string
}
export type AgentQueryResponse = Response<Array<Agent>>

export const initialAgent: Agent = {
  agentCode: '',
  firstName: '',
  lastName: '',
  companyName: '',
  emailAddress: '',
  addressLine1: '',
  addressLine2: '',
  city: undefined,
  stateId: 1,
  zipCode: '',
  commissionPercent: 0,
  isActive: true,
  rateId: 1,
  enableLogin: false,
  userName: '',
  password: '',
  statusId: 0,
  stateName: '',
  mobileNumber: undefined
  , agentImagePath: undefined

}

export type agentImage = {
  name?: string,
  extension?: string,
  data?: string,
}

export type AgentRecord = {
  totalCustomerCount?: number,
  totalSaleOrderCount?: number,
  totalSaleOrderAmount?: number,
  totalAgentCommission?: number,
  totalPaidCommission?: number
}


export type TransactionHistory = [
  {
    saleOrderId?: number,
    orderNumber?: string,
    orderStatusName?: string,
    orderDate?: Date
    orderTotal?: number
  }
]

export type AgentCommission = {
  id?: number,
  agentId?: number,
  agentName?: string,
  saleOrderId?: number,
  orderNumber?: string,
  orderTotal?: number,
  agentCommissionStatusId?: number,
  agentCommissionStatusName?: string,
  earnCommission?: number

}

export type AgentCommissionQueryResponse = Response<Array<AgentCommission>>


export type AgentPayout = {
  agentId?: number,
  agentName?: string
  amountPaid?: number,
  accountId?: number,
  paymentDate?: Date,
  paymentModeId?: number,
  paymentModeName?: string
  referenceNumber?: string,
  notes?: string
  totalAmountPaid?: number
  agentCommissionIds?: number[]
}
export type AgentPayoutQueryResponse = Response<Array<AgentPayout>>


