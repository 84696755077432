import { TestimonialListSearchComponent } from './TestimonialListSearchComponent'
import { TestimonialListToolbar } from './TestimonialListToolbar'
const TestimonialListHeader = () => {

  return (
    <div className='card-header'>
      <TestimonialListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <TestimonialListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default TestimonialListHeader
