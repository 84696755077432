import { FC, useEffect, useState } from 'react'
import { Result } from '../../../../../core/models/Result'

import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { getOrganizatinProfile, updateCreditNote, updateInvoiceSetting } from '../../../core/_requests'
import { CreditNoteSetting, InvoiceSetting, OrganizationProfile } from '../../../core/_models'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'

type Props = {
  data: Result | InvoiceSetting
}

const invoiceSchema = Yup.object().shape({
  termCondition: Yup.string(),
  customerNotes: Yup.string(),
  isEditAllowedSentInvoice: Yup.string()
})
const InvoiceForm: FC<Props> = ({ data }) => {
  const invoiceDetail = data as InvoiceSetting
  const API_URL = process.env.REACT_APP_IMAGE_API_URL
  const [orgProfile, setOrgProfile] = useState<OrganizationProfile>();

  const initialValues: InvoiceSetting = {
    id: invoiceDetail.id,
    termCondition: invoiceDetail.termCondition,
    customerNotes: invoiceDetail.customerNotes,
    isEditAllowedSentInvoice: invoiceDetail.isEditAllowedSentInvoice
  }



  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: invoiceSchema,
    onSubmit: async (formValues, { setSubmitting }) => {
      setSubmitting(true)

      let invoice: InvoiceSetting = {
        id: formValues.id,
        termCondition: formValues.termCondition,
        customerNotes: formValues.customerNotes,
        isEditAllowedSentInvoice: formValues.isEditAllowedSentInvoice
      }
      let result: Result
      result = await updateInvoiceSetting(invoice)
      toast.success("Invoice Updated Successfully.")
    },
  })


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrganizatinProfile();
        const profile = response as OrganizationProfile;
        setOrgProfile(profile);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <FormikProvider value={formik}>
      <KTCard>
        <div className="card-header">
          <div className="card-title">
            <h3>Invoice</h3>
          </div>
        </div>
        <KTCardBody>
          <form
            id='kt_modal_add_invoice_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >

            {/* <div className='row mb-7'>
              <div className='col-6 col-md-6 mb-4'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Invoice Editable</label>

                <div className='form-check form-check-custom form-check-solid ms-1'>

                  <input
                    className='form-check-input ms-1'
                    type='checkbox'
                    {...formik.getFieldProps('isEditAllowedSentInvoice')}
                    checked={{ ...formik.getFieldProps('isEditAllowedSentInvoice') }.value}


                  /><span className='ms-4'>is Invoice Editable</span>
                </div>
                {formik.touched.isEditAllowedSentInvoice && formik.errors.isEditAllowedSentInvoice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.isEditAllowedSentInvoice}</span>
                    </div>
                  </div>
                )}
              </div>

            </div> */}

            <div className='mb-7 row '>

              <label className='form-label'>Terms & Conditions</label>
              <textarea
                rows={5}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.termCondition && formik.errors.termCondition },
                  {
                    'is-valid': formik.touched.termCondition && !formik.errors.termCondition,
                  }
                )}
                placeholder="Enter the terms and conditions of your business to be displayed in your transaction"
                {...formik.getFieldProps('termCondition')}
                name='termCondition'
              />
              {formik.touched.termCondition && formik.errors.termCondition && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.termCondition}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row mb-7'>
              {/* <div className='col-6 col-md-6'> */}
              <label className='form-label'>Customer Notes</label>
              <textarea
                rows={5}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.customerNotes && formik.errors.customerNotes },
                  {
                    'is-valid': formik.touched.customerNotes && !formik.errors.customerNotes,
                  }
                )}
                placeholder='Thanks for your business.'
                {...formik.getFieldProps('customerNotes')}
                name='customerNotes'
              />
              {formik.touched.customerNotes && formik.errors.customerNotes && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.customerNotes}</span>
                  </div>
                </div>
              )}
            </div>
            {/* </div> */}
            {MustHavePermission(SWSection.Store, SWResource.InvoiceSettings, SWAction.Update) ?
            <button type='submit' className='btn btn-primary '>
              Save
            </button>:null}
          </form>
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export { InvoiceForm }
