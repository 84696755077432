import { useMemo } from "react"
import { useQueryRequest } from "../core/QueryRequestProvider"
import { useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from "../core/QueryResponseProvider"
import { PaymentColumns } from "./columns"
import { ColumnInstance, Row, useTable } from "react-table"
import { KTCardBody } from "../../../../../_metronic/helpers"
import { Payment } from "../core/_model"
import { CustomHeaderColumn } from "./CustomHeaderColumn"
import { CustomRow } from "./CustomRow"
import { Loading } from "../../../../core/shared/components/Loading"
import Pagination from "../../../../core/shared/components/Pagination"
import { PaymentListToolbar } from "../components/header/header/PaymentListToolbar"


const PaymentTable = () => {
  const pagination = useQueryResponsePagination()
  const { updateState } = useQueryRequest()
  const payment = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => payment, [payment])
  const columns = useMemo(() => PaymentColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <>
      <div className='card-header justify-content-between align-items-center'>
        <div className='card-title'>
          <h3>Payment</h3>
        </div>
        <PaymentListToolbar />
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_rawmaterial'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<Payment>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Payment>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination pagination={pagination} isLoading={isLoading} updateState={updateState} />

        {isLoading && <Loading />}
      </KTCardBody>
    </>
  )
}

export { PaymentTable }
