import React, { useEffect, useState } from 'react'
import { AgentRecord, TransactionHistory } from '../core/_models'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { getRecordById, transactionHistory } from '../core/_requests'
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter'

const AgentOverview = () => {
    const [recordDetails, setRecordDetails] = useState<AgentRecord>()
    const [transactions, setTransactions] = useState<TransactionHistory>()
    const { id } = useParams()
    useEffect(() => {


        const fetchRecord = async () => {
            const response = await getRecordById(id)
            console.log(response)
            setRecordDetails(response as AgentRecord)
        }
        fetchRecord()

        const transactionHistoryDetails = async () => {
            const response = await transactionHistory(id)
            setTransactions(response as TransactionHistory)
        }
        transactionHistoryDetails()

    }, []);


    const formatDate = (rowData: any, dateFormat: string) => {
        const date = new Date(rowData);
        return format(date, dateFormat);
    };


    const navigate = useNavigate()
    return (


        <>
            <div className='d-flex flex-row '>
                <div className="card pt-4 h-md-100 mb-6 mb-md-7 me-3 col-md-6">
                    <div className="card-body pt-0">
                        <div className="fw-bold fs-2">
                            <div className="ms-2">
                                <div className=" py-3 px-3 mb-3">
                                    <div className="fw-bold text-gray-700 fs-2 text-center">
                                        <span className="w-50px text-center text-success fw-bold " style={{ fontSize: '34px' }}>{recordDetails?.totalCustomerCount}</span>
                                    </div>
                                    <div className="fw-bold text-primary fs-3  text-center">Customer Count</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card pt-4 h-md-100 mb-6 mb-md-7 me-3 col-md-6">
                    <div className="card-body pt-0">
                        <div className="fw-bold fs-2">
                            <div className="ms-2">
                                <div className=" py-3 px-3 mb-3">
                                    <div className="fs-4 fw-bold text-gray-700 text-center">
                                        <span className="w-50px text-center text-success fw-bold " style={{ fontSize: '34px' }}>{recordDetails?.totalSaleOrderCount}</span>
                                    </div>
                                    <div className="fw-bold text-primary fs-3 text-center">Sales Count</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row">
                <div className="card pt-4 h-md-100 mb-6 mb-md-7 me-3 col-md-6">

                    <div className="card-body pt-0">
                        <div className="fw-bold fs-2">

                            <div className="ms-2">
                                <div className=" py-3 px-3 mb-3">
                                    <div className="fs-4 fw-bold text-gray-700 text-center">
                                        <span className="w-50px text-center text-success fw-bold" style={{ fontSize: '34px' }}>{formatCurrencyInINR(recordDetails?.totalAgentCommission)}</span>

                                    </div>
                                    <div className="fw-bold text-primary fs-3 text-center">Total Commission Earned</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card pt-4 h-md-100 mb-6 mb-md-7 me-3 col-md-6">

                    <div className="card-body pt-0">
                        <div className="fw-bold fs-2">
                            <div className="ms-2">
                                <div className="py-3 px-3 mb-3">
                                    <div className="fs-4 fw-bold text-gray-700 text-center ">
                                        <span className="w-50px text-center text-success fw-bold" style={{ fontSize: '34px' }}>{formatCurrencyInINR(recordDetails?.totalPaidCommission)}</span>
                                    </div>
                                    <div className="fw-bold text-primary fs-3 text-center">Total Commission Paid</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card pt-4 mb-6 mb-xl-9">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Transaction History</h3>
                    </div>
                </div>

                <div className="card-body pb-5">
                    <div
                        id="kt_table_customers_payment_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                        <div className="table-responsive">
                            <table
                                className="table align-middle table-row-dashed gy-5 dataTable no-footer"
                                id="kt_table_customers_payment"
                            >
                                <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                                    <tr className="text-start text-muted text-uppercase gs-0">
                                        <th
                                            className="min-w-100px sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_customers_payment"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="order No.: activate to sort column ascending"
                                            style={{ width: "144.578px" }}

                                        >
                                            order No.
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_customers_payment"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Status: activate to sort column ascending"
                                            style={{ width: "122.688px" }}
                                        >
                                            Status
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_customers_payment"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{ width: "118.375px" }}
                                        >
                                            Amount
                                        </th>

                                        <th
                                            className="min-w-100px sorting_disabled"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date"
                                            style={{ width: "227.188px" }}
                                        >
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="fs-6 fw-semibold text-gray-600">
                                    {transactions && transactions?.length > 0 ?
                                        (transactions?.map((order) => (
                                            <tr onClick={() => { navigate(`/sales/sale-order/detail/${order.saleOrderId}`) }} style={{ cursor: 'pointer' }}>
                                                <td>
                                                    <a

                                                        className="text-gray-600 text-hover-primary mb-1"
                                                    >
                                                        {order.orderNumber}
                                                    </a>
                                                </td><td>
                                                    {order.orderStatusName === 'Cancelled' ?
                                                        <span className="badge badge-light-danger">
                                                            {order.orderStatusName}
                                                        </span> : <span className="badge badge-light-success">
                                                            {order.orderStatusName}
                                                        </span>}
                                                </td>
                                                <td>{order.orderTotal ? formatCurrencyInINR(order.orderTotal) : '₹0.0'} </td>

                                                <td>{formatDate(order.orderDate, 'dd/MM/yyyy')} </td>
                                            </tr>
                                        ))) : <span className=' '><h3 className='text-danger mt-5'>No Record found</h3></span>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>






    )
}

export default AgentOverview