import { FC, useEffect, useState } from 'react'
import { useImmer } from 'use-immer';
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { PaginationFilter, KTSVG } from '../../../../../_metronic/helpers';
import { Result } from '../../../../core/models/Result';
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import { Loading } from '../../../../core/shared/components/Loading';
import { camelize } from '../../../../core/utils/StringHelpers';
import { getProductColor,  getSalesOrderInvoiceById, getSalesOrderList } from '../../salesOrder/salesOrder-list/core/_requests';
import { Invoice, invoiceItemList, OtherCharges, } from '../core/_models';
import { createInvoice } from '../core/_requests';
import { ColorDetails, SaleOrderDto, SaleOrderForInvoice, SalesOrder,  } from '../../salesOrder/salesOrder-list/core/_models';
import Flatpickr from 'react-flatpickr';
import { SalesOrderSetting } from '../../../settings/core/_models';
import { getSalesOrderSetting } from '../../../settings/core/_requests';
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect';
import { Customer, CustomerDetailDto } from '../../customer/customer-list/core/_models';
import { getCustomerById, getCustomerList } from '../../customer/customer-list/core/_requests';
import ProductSearchbar from '../../../../core/shared/components/ProductSearchbar';
import { Product } from '../../../catalog/product/product-list/core/_models';
import { getOtherChargeList, getPlaceOfSupplyList, getPolishingTypeList, getStateList } from '../../../../core/shared/core/_request';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { getCategoryList } from '../../../catalog/category/category-list/core/_requests';
import { Category } from '../../../catalog/category/category-list/core/_models';
import { PlaceOfSupply, PolishingType } from '../../../../core/shared/core/_model';
import { getProductGroupList } from '../../../catalog/productGroup/productgroup-list/core/_requests';
import { ProductGroup } from '../../../catalog/productGroup/productgroup-list/core/_models';
import { getProductById } from '../../../catalog/product/product-list/core/_requests';
import Select from 'react-select';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,

};
const invoiceSchema = Yup.object().shape({
  customerId: Yup.number().required('Please select customer'),
  //invoiceDate: Yup.string()
  // .required("Please enter invoice date")
  // .max(new Date(), 'Invoice Date cannot be greater than the current date')
  // .min(new Date('1090-01-01'), 'Invoice Date cannot be earlier than January 1, 1090'),

})

const initialValues = {
  customerId: undefined,
  saleOrderId: undefined,
  invoiceDate: new Date(),
  dueDate: new Date(),
  discountValue: 0,
  adjustment: 0,
  roundOff: 0,
  orderTotal: 0,
  orderSubTotal: 0,
  totalDiscountedPrice: 0,
  totalTaxAmount: 0,
  billAddressLine1: "",
  billAddressLine2: "",
  billStateId: "",
  billStateName: "",
  billCityName: "",
  shipAddressLine1: "",
  shipAddressLine2: "",
  shipStateId: "",
  shipStateName: "",
  shipCityName: "",
  placeOfSupplyId: undefined,
  otherCharges: [
    {
      rowNumber: 0,
      id: 0,
      label: '',
      value: undefined,
      sign: 0,
      isDeleted: false
    },
  ],
}
const InvoiceAddPage: FC = () => {

  const { id: saleId } = useParams();
  const checkSaleID = Boolean(saleId);
  const [otherCharges, setOtherCharges] = useImmer<OtherCharges[]>([])
  const [deliverDate, setDeliveryDate] = useState<SalesOrderSetting>()
  const [saleOrderDetail, updateSaleOrderDetail] = useState<SaleOrderForInvoice>()
  const [saleOrderList, setSaleOrderList] = useState<SalesOrder[]>([])
  const [customerData, setCustomerData] = useState<Customer>()
  const [saleOrderItems, updateSaleOrderItems] = useImmer<invoiceItemList[]>([]);
  const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([]);
  const [productGroupList, setProductGroupList] = useState<SelectOptionProps[]>([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [polishId, setPolishId] = useState<number | null>(null)
  const [colorList, setColorList] = useState<ColorDetails[]>([]);
  const [polishingTypeList, setPolishingTypeList] = useState<SelectOptionProps[]>([]);
  const [quantities, setQuantities] = useState<{ [colorId: number]: number }>({}); //state to store color quantity
  const [errorMessage, updateErrorMessage] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [placeOfSupply, setPlaceOfSupply] = useState<SelectOptionProps[]>([]);
  const navigate = useNavigate();
  const [isBillingFormVisible, setBillingFormVisibility] = useState(false);
  const [errorMessages, setErrorMessages] = useState<{ [index: number]: string }>({});
  const isValidNumberString = (str) => /^-?\d+(\.\d+)?$/.test(str);

  const handleOtherChargeValueChange = (index: number, value: number) => {
    setOtherCharges(draft => {
      draft[index].value = value;
    });
    if (!isValidNumberString(value)) {
      setErrorMessages(prev => ({ ...prev, [index]: 'Invalid input: Not a valid number' }));
      return;
    }
    if (value < 0) {
      setErrorMessages(prev => ({ ...prev, [index]: 'Value cannot be negative' }));

    } else {
      setOtherCharges(draft => {
        draft[index].value = value;
      });
      setErrorMessages(prev => ({ ...prev, [index]: '' }));
    }
  };
  const toggleBillingFormVisibility = () => {
    setBillingFormVisibility(!isBillingFormVisible);
    setError('')
  };

  const [dateState, setDateState] = useState<any>({
    date1: new Date(),
    date2: new Date()
  });
  const today = new Date();
  const minDate = new Date('1900-01-01');
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: checkSaleID ? null : invoiceSchema,
    initialValues: checkSaleID
      ? {
        saleOrderId: saleOrderDetail?.id,
        customerId: saleOrderDetail?.customerId,
        customerName: saleOrderDetail?.customerName,
        invoiceDate: dateState.date1,
        dueDate: dateState.date2,
        totalDiscountedPrice: saleOrderDetail?.totalDiscountedPrice,
        roundOff: saleOrderDetail?.roundOff,
        orderTotal: saleOrderDetail?.orderTotal,
        orderSubTotal: saleOrderDetail?.subTotal,
        totalTaxAmount: saleOrderDetail?.totalTaxAmount,
        otherCharges: otherCharges.filter((item) => !item.isDeleted),
        itemList: saleOrderItems.filter((item) => !item.isDeleted),
        billAddressLine1: saleOrderDetail?.billAddressLine1,
        billAddressLine2: saleOrderDetail?.billAddressLine2,
        billCityName: saleOrderDetail?.billCityName,
        billStateId: saleOrderDetail?.billStateId,
        billStateName: saleOrderDetail?.billStateName,
        billZipCode: saleOrderDetail?.billZipCode,
        shipAddressLine1: saleOrderDetail?.shipAddressLine1,
        shipAddressLine2: saleOrderDetail?.shipAddressLine2,
        shipStateId: saleOrderDetail?.shipStateId,
        shipStateName: saleOrderDetail?.shipStateName,
        shipCityName: saleOrderDetail?.shipCityName,
        placeOfSupplyId: saleOrderDetail?.placeOfSupplyId
      }
      : initialValues,

    onSubmit: async (formValues, { setFieldError, setSubmitting }) => {

      updateErrorMessage('')
      if (new Date(dateState.date1 as Date) > today || new Date(dateState.date1 as Date) < minDate) {
        formik.setFieldError('invoiceDate', 'Please enter valid Date')
        return
      }
      if (saleOrderItems.filter((item) => !item.isDeleted).length === 0) {
        updateErrorMessage('Alteast one product is required')
        window.scrollTo(0, 0);
        return;
      }
      if (saleOrderItems.filter((item) => !item.isDeleted && item.productId == 0).length > 0) {
        updateErrorMessage('Select Valid Product')
        window.scrollTo(0, 0);

        return;
      }
      if (saleOrderItems.filter((item) => !item.isDeleted && item.quantity == 0).length > 0) {
        updateErrorMessage('Please ensure that the quantity cannot be zero')
        window.scrollTo(0, 0);

        return;
      }
      if (formValues?.orderTotal as number <= 0) {
        updateErrorMessage('Please ensure that the total amount is greater than or equal to zero.')
        window.scrollTo(0, 0);

        return;
      }
      setSubmitting(true)
      const invDate = toZonedTime(formValues.invoiceDate as Date, 'Asia/Kolkata');
      formValues.invoiceDate = format(invDate , 'yyyy-MM-dd')

      const DueDate = toZonedTime(formValues.dueDate as Date, 'Asia/Kolkata');
      formValues.dueDate = format(DueDate , 'yyyy-MM-dd')
      try {
        debugger
        let result: Result
        let product: Invoice = {
          customerId: formValues.customerId as number,
          saleOrderId: formValues.saleOrderId as unknown as number,
          placeOfSupplyId: formValues.placeOfSupplyId,
          // invoiceDate:formValues.invoiceDate,
          // dueDate:formValues.dueDate,
          invoiceDate: formValues.invoiceDate,//dateState.date1,
          dueDate:formValues.dueDate,// dateState.date2,
          otherCharges: otherCharges.filter((item) => !item.isDeleted),
          itemList: saleOrderItems.filter((item) => !item.isDeleted),
          billAddressLine1: formValues.billAddressLine1,
          billAddressLine2: formValues.billAddressLine2,
          billCityName: formValues.billCityName,
          billStateId: formValues.billStateId as number,
          billStateName: formValues.billStateName,
          shipAddressLine1: formValues.shipAddressLine1,
          shipAddressLine2: formValues.shipAddressLine2,
          shipStateId: formValues.shipStateId as number,
          shipStateName: formValues.shipStateName,
          shipCityName: formValues.shipCityName,
        }
        result = await createInvoice(product)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Invoice created successfully!');

          navigate('/sales/invoice/list')
        }
        else if (result.statusCode === 400) {
          result.propertyResults.map((error) =>
            setFieldError(camelize(error.propertyName), error.errorMessage)
          )
          toast.error('An error occurred while saving the invoice.');
        }

        else if (result.succeeded === false) {
          toast.error(result.messages[0])
        }

      } catch (ex) {
        console.error(ex)
      }
    },
  })

  useEffect(() => {
    const fetchSaleOrderDetail = async () => {
      await getSalesOrderInvoiceById(saleId).then((v) => {
        const result = v as SaleOrderForInvoice;
        updateSaleOrderDetail(result);
        const saleOrderItems = result.itemList;

        updateSaleOrderItems((soitems) => {
          // Clear existing items
          soitems.splice(0, soitems.length);
          saleOrderItems.forEach((item, index) => {
            soitems.push({
              rowNumber: index,
              isDeleted: false,
              productId: item.productId as number,
              productName: item.productName,
              colorName: item.colorName,
              polishingTypeName: item.polishingTypeName,
              colorId: item.colorId,
              polishingTypeId: item.polishingTypeId,
              quantity: item.quantity,
              productPrice: item.productPrice,
              subTotal: item.subTotal,
              discountPercent: item.discountPercent,
              discountAmount: item.discountAmount,
              total: item.total,
              taxPercent: item.taxPercent || 0,
              taxAmount: item.taxAmount || 0,
              totalInclusiveTax: item.totalInclusiveTax,

            });
          });
        });

        const otherChargesItems = result.otherCharges;
        setOtherCharges((ocitems) => {
          ocitems.splice(0, ocitems.length)
          otherChargesItems.forEach((item, index) => {
            ocitems.push({
              id: item.id,
              rowNumber: index,
              label: item.label,
              value: item.value,
              sign: item.sign,
              isDeleted: false
            })
          })
        })

      });

    }

    if (checkSaleID) {
      fetchSaleOrderDetail();
    }

  }, [saleId])

  useEffect(() => {

    calculateTotal()
    
  }, [saleOrderItems, otherCharges,]);

  useEffect(() => {
    const invoiceDateValue = formik.values.invoiceDate;
    let invoiceDate: Date;
    
    if (invoiceDateValue instanceof Date) {
      invoiceDate = invoiceDateValue;
    } else {
      invoiceDate = new Date(invoiceDateValue);
    }
  
    const dueDate = new Date(invoiceDate);
  
    if (deliverDate !== undefined && deliverDate.defaultDeliveryTime !== undefined) {
      dueDate.setDate(invoiceDate.getDate() + deliverDate.defaultDeliveryTime);
      formik.setFieldValue('dueDate', dueDate);
    }
  }, [formik.values.invoiceDate, deliverDate]);
  const totalDiscountAmount = () => {
    let totalDiscount: number = 0;
    saleOrderItems.filter(t => !t.isDeleted).map((soitem) => {
      totalDiscount += (soitem.discountAmount || 0)
    })
    return totalDiscount.toFixed(2);
  }

  const totalTaxAmount = () => {
    let totalTaxAmt: number = 0;
    saleOrderItems.filter(t => !t.isDeleted).map((soitem) => {
      totalTaxAmt += (soitem.taxAmount || 0)
    })
    return totalTaxAmt.toFixed(2);
  }

  const calculateTotal = () => {

    let tempTotal: number = 0;
    let total: number = 0;
    let subTotal: number = 0;
    let otherChargesTotal: number = 0;
    let discount: number = 0
    let taxAmount: number = 0

    saleOrderItems.filter(t => !t.isDeleted).map((item) => {
      subTotal = subTotal + (item.subTotal as number);
      discount = discount + (item.discountAmount as number)
      taxAmount = taxAmount + (item.taxAmount as number)

    })

    otherCharges.filter(t => !t.isDeleted).map((item) => {

      if (item.sign === 1) {
        otherChargesTotal = otherChargesTotal + (item?.value as number);
      } else if (item.sign === 2) {
        otherChargesTotal = otherChargesTotal - (item?.value as number);
      }
    })

    tempTotal = subTotal + otherChargesTotal - discount + taxAmount;
    total = Math.round(tempTotal)
    formik.setFieldValue('orderSubTotal', subTotal);
    formik.setFieldValue('totalDiscountedPrice', discount);
    formik.setFieldValue('roundOff', total - tempTotal);
    formik.setFieldValue('orderTotal', total);
    formik.setFieldValue('totalTaxAmount', taxAmount)
  };



  const onSaleOrderChange = async (e: any) => {
    if (e === null) {
      updateSaleOrderItems([])
    }
    else {

      await getSalesOrderInvoiceById(e.value).then((v) => {
        const result = v as SaleOrderForInvoice;

        updateSaleOrderDetail(result)
        const saleOrderItems = result.itemList;


        updateSaleOrderItems((soitems) => {

          soitems.splice(0, soitems.length);
          saleOrderItems.forEach((item, index) => {
            soitems.push({
              rowNumber: index,
              isDeleted: false,
              productId: item.productId as number,
              productName: item.productName,
              colorName: item.colorName,
              polishingTypeName: item.polishingTypeName,
              colorId: item.colorId,
              polishingTypeId: item.polishingTypeId,
              quantity: item.quantity,
              productPrice: item.productPrice,
              subTotal: item.subTotal,
              discountPercent: item.discountPercent,
              discountAmount: item.discountAmount,
              total: item.total,
              taxPercent: item.taxPercent,
              taxAmount: item.taxAmount,
              totalInclusiveTax: item.totalInclusiveTax,


            });
          });
        });

        const othercharges = result.otherCharges
        setOtherCharges((ocitems) => {
          ocitems.splice(0, ocitems.length)
          othercharges.forEach((item, index) => {
            ocitems.push({
              id: item.id,
              rowNumber: index,
              isDeleted: false,
              label: item.label,
              sign: item.sign,
              value: item.value
            })
          })
        })
      });
    }
  };
  const [isFormVisible, setFormVisibility] = useState(false);
  const toggleFormVisibility = () => {
    setFormVisibility(!isFormVisible);
  };

  function useFetchAndMapData(getDataFunction: any, mapFunction: any) {
    const [dataList, setDataList] = useState([]);
    useEffect(() => {

      const updatedPaginationFilter: PaginationFilter = {
        ...paginationFilter,
        advancedFilter: {
          field: 'isActive',
          operator: 'eq',
          value: true,
        },
      }
      getDataFunction(updatedPaginationFilter).then((response: { data: any; }) => {
        const result = response.data;
        const mappedData = result.map((item: any) => mapFunction(item));
        setDataList(mappedData);
      });
    }, []);

    return dataList;
  }

  const stateList = useFetchAndMapData(getStateList, (item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const chargesList = useFetchAndMapData(getOtherChargeList, (item: any) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    let result;
    getPlaceOfSupplyList(paginationFilter).then((v) => {
      result = v.data as PlaceOfSupply[];
      let placeOfSupplyArray: any[] = [];
      result.map((item) => {
        placeOfSupplyArray.push({ value: item.id, label: item.alias });
      });
      setPlaceOfSupply(placeOfSupplyArray);
    });
  }, [])

  useEffect(() => {
    getSalesOrderSetting().then((v) => {
      setDeliveryDate(v as SalesOrderSetting);
    });
  }, []);


  const dateChange = (e: any) => {
    debugger
    const dueDate = new Date(e);
    if (deliverDate !== undefined && deliverDate.defaultDeliveryTime !== undefined) {
      dueDate.setDate(dueDate.getDate() + deliverDate?.defaultDeliveryTime);
      formik.setFieldValue('dueDate', dueDate);
    }
  }

  const sign = [
    { value: 1, label: 'add' },
    { value: 2, label: 'less' },

  ]

  const handleAddRow = () => {
    setOtherCharges((ocitems) => {
      ocitems.push({
        rowNumber: ocitems.length,
        value: 0,
        isDeleted: false
      })
    })

  };


  const onCustomerChange = async (e: any) => {

    await getCustomerById(e.value).then((v) => {
      const result = v as CustomerDetailDto;

      if (result.shippingAddress === null || result.shippingAddress.addressLine1 === '') {      
        setError('Shipping address is required')
      }
      
      else if (result.billingAddress?.addressLine1 === '' || result.billingAddress === null) {
        setError('Billing address is required')
      }
      else {
        setError('')
      }
      setCustomerData(result)
      if (result.billingAddress) {
        formik.setFieldValue('billAddressLine1', result.billingAddress.addressLine1);
        formik.setFieldValue('billAddressLine2', result.billingAddress.addressLine2);
        formik.setFieldValue('billStateId', result.billingAddress.stateId);
        formik.setFieldValue('billCityName', result.billingAddress.city);
      } else {
        formik.setFieldValue('billAddressLine1', '');
        formik.setFieldValue('billAddressLine2', '');
        formik.setFieldValue('billStateId', '');
        formik.setFieldValue('billCityName', '');
      }
      if (result.shippingAddress) {
        formik.setFieldValue('shipAddressLine1', result.shippingAddress.addressLine1)
        formik.setFieldValue('shipAddressLine2', result.shippingAddress.addressLine2)
        formik.setFieldValue('shipStateId', result.shippingAddress.stateId)
        formik.setFieldValue('shipCityName', result.shippingAddress.city)
      }
      else {
        formik.setFieldValue('shipAddressLine1', '')
        formik.setFieldValue('shipAddressLine2', '')
        formik.setFieldValue('shipStateId', '')
        formik.setFieldValue('shipCityName', '')
      }

    })
    let result: any
    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        field: 'customerId',
        operator: 'eq',
        value: e.value,
      },
    }

    await getSalesOrderList(updatedPaginationFilter).then((v) => {
      result = v.data as SaleOrderDto[];
      let saleOrderArray: any[] = [];
      result.map((item) => {
        saleOrderArray.push({ value: item.id, label: item.orderNumber });
      });
      setSaleOrderList(saleOrderArray);
    });
  }

  const onProductsChange = (e: Product, quantity: number = 1) => {
    updateSaleOrderItems((soitems) => {
      var soItem = soitems.find(p => p.productId == e.id);


      if (soItem !== undefined) {
        soItem.quantity = (soItem.quantity as number) + quantity;

      }
      else {
        let perProductPrice: number = 0, discountAmount: number = 0;
        let total: number = 0, subTotal = 0;
        let taxAmount: number = 0;
        let temptotal: number = 0;
        if (customerData?.rateId === 1) {
          perProductPrice = e.spWholeSeller as number;
        }
        else if (customerData?.rateId === 2) {
          perProductPrice = e.spSemiWholeSeller as number;
        }
        else {
          perProductPrice = e.spRetail as number;
        }

        subTotal = perProductPrice * parseInt(quantity.toString(), 10)

        if (customerData === undefined) {
          discountAmount = 0;

        } else {
          discountAmount =
            (subTotal * (customerData?.discountPercent as number)) / 100;
        }
        temptotal = subTotal - discountAmount
        if (e.taxRateValue === undefined) {
          taxAmount = 0
        }
        else {
          taxAmount = (temptotal * (e.taxRateValue as number)) / 100;
        }
        total = temptotal + taxAmount


        soitems.push({
          rowNumber: soitems.length,
          polishingTypeName: e.polishingTypeName,
          polishingTypeId: e.polishingTypeId,
          colorId: e.colorId,
          colorName: e.colorName,
          productId: e.id as number,
          productName: e.name,
          quantity: quantity,
          productPrice: perProductPrice,
          subTotal: (perProductPrice * quantity),
          discountPercent: customerData?.discountPercent,
          discountAmount: discountAmount,
          total: total,
          isDeleted: false,
          taxPercent: e.taxRateValue || 0,
          taxAmount: taxAmount || 0,
          totalInclusiveTax: total
        });
      }
    })
  };

  const updateItemTotal = (rowNumber: number) => {
    updateSaleOrderItems((soitems) => {


      const soItem = soitems.find(t => t.rowNumber === rowNumber);
      let unitPrice: number
      let qty: number
      let discPercent: number
      let total: number = 0, discAmt: number = 0;
      let taxValue: number = 0, taxAmt: number = 0;
      let tempTotal: number = 0;
      let totalTaxAmt: number = 0;
      if (soItem != null) {
        unitPrice = soItem.productPrice as number;
        qty = soItem.quantity as number;
        discPercent = soItem.discountPercent as number;
        taxValue = soItem.taxPercent as number
        if (discPercent > 0) {
          discAmt = (unitPrice * qty) * discPercent / 100
        }
        tempTotal = (unitPrice * qty) - discAmt
        if (taxValue > 0) {
          taxAmt = (tempTotal * taxValue) / 100
        }

        total = (unitPrice * qty);
        totalTaxAmt = tempTotal + taxAmt
        soItem.subTotal = total
        soItem.discountAmount = discAmt;
        soItem.total = tempTotal;
        soItem.totalInclusiveTax = totalTaxAmt;
        soItem.taxAmount = taxAmt

      }


    });
  }


  useEffect(() => {
    let result: any
    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },

    };
    getCategoryList(updatedPaginationFilter).then((v) => {
      result = v.data as Category[];
      let categoryArray: any[] = [];
      result.map((item) => {
        categoryArray.push({ value: item.id, label: item.parentChildCategoryName });
      });
      setCategoryList(categoryArray);
    });


    getPolishingTypeList(updatedPaginationFilter).then((v) => {
      result = v.data as PolishingType[]
      let polishingTypeArray: any[] = []
      result.map((item: any) => {
        return polishingTypeArray.push({ value: item.id, label: item.name })
      })
      setPolishingTypeList(polishingTypeArray)
    })


  }, [selectedCategory]);

  const onCategoryChange = (e) => {

    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        filters: [
          { field: 'isActive', operator: 'eq', value: true },

          {
            field: "categoryId",
            operator: "eq",
            value: e.value,
          }
        ],
        logic: "and"
      }
    };

    getProductGroupList(updatedPaginationFilter)
      .then((response) => {
        const result = response.data as ProductGroup[];
        let pgArray: any[] = []
        result.map((item: any) => {
          return pgArray.push({ value: item.id, label: item.name })
        })
        setProductGroupList(pgArray);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  const handleSearch = () => {
    getProductColor(selectedProductGroup as number, polishId as number)
      .then((response) => {
        const result = response as ColorDetails[]
        setColorList(result)
      })

  };

  const handlePolishingChange = (e) => {
    setPolishId(e.value)
  }


  const handleQuantityChange = (colorId: number, quantity: number) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [colorId]: quantity
    }));
  };

  const handleAddClick = () => {
    colorList.forEach(async color => {
      const quantity = quantities[color.colorId];

      if (quantity > 1 || (quantity !== undefined && quantity !== 1)) {
        try {

          const product = await getProductById(color.productId);


          onProductsChange(product as Product, quantity);
        } catch (err) {
          console.error(`Error fetching product by ID ${color.productId}:`, err);
        }
      }
    });
    setSelectedProductGroup(null);
    setSelectedCategory(null);
    setPolishId(null)
    setPolishingTypeList([])
    setColorList([])
    setQuantities({})
  };


  const clearFilters = () => {

    setSelectedProductGroup(null);
    setSelectedCategory(null);
    setPolishId(null)
    setPolishingTypeList([])
    setColorList([])
  }
  const handleDelete = (e: React.ChangeEvent<HTMLInputElement>, rowNumber: number) => {
    updateSaleOrderItems(draft => {
      return draft.filter(item => item.rowNumber !== rowNumber);
    });
  };

  return (
    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_invoice_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column flex-row-fluid"'
          id='kt_modal_add_invoice_scroll'        >
          <div className="d-flex flex-column flex-row-fluid gap-4 gap-lg-10 mb-7">
            <div className="tab-content">
              <div className="tab-pane fade show active"
                id="kt_ecommerce_add_invoice_general"
                role="tab-panel">
                {error !== '' && (
                  <div className='alert alert-danger'>
                    {error}
                  </div>
                )}
                {errorMessage != '' && (
                  <div className='alert alert-danger'>
                    {errorMessage}
                  </div>
                )}

                <div className="d-flex flex-column gap-7 gap-lg-7">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">
                        <h3>Order Details</h3>
                      </div>

                    </div>
                    <div className="card-body">
                      <div className='row mb-0'>
                        <div className="col-3 col-md-3 ">
                          <label className="form-label required">Customer</label>
                          {checkSaleID ?
                            <Field
                              className='form-control'
                              disabled={true}
                              name={`customerId`}
                              value={saleOrderDetail?.customerName}
                            ></Field>
                            :
                            <Field
                              className='form-select-solid-solid'
                              component={CustomAsyncSelect<Customer>}
                              {...formik.getFieldProps(`customerId`)}
                              name={`customerId`}
                              loadOptionFunc={getCustomerList}
                              searchByField="printName"
                              labelField="printName"
                              disabledisActiveFilter={true}
                              onDropDownChange={(newValue: any) => {
                                onCustomerChange(newValue)
                                formik.setFieldValue('customerId', newValue?.value)

                              }}
                            ></Field>
                          }
                          <div className="text-muted fs-7">Select the Customer.</div>
                          {formik.touched.customerId && formik.errors.customerId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.customerId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-3 col-md-3 ">
                          <label className="form-label ">Sale Order Number</label>

                          {checkSaleID ?
                            <Field
                              className='form-control'
                              disabled={true}
                              value={saleOrderDetail?.orderNumber}
                            ></Field>
                            :
                            <Field
                              className='form-select-solid'
                              options={saleOrderList}
                              component={CustomSelect}
                              name={'saleOrderId'}
                              onDropDownChange={(newValue: any) => {
                                onSaleOrderChange(newValue)
                                formik.setFieldValue('saleOrderId', newValue?.value)
                              }}
                              isClearable={true}
                              isMulti={false}
                            >
                            </Field>
                          }
                          <div className="text-muted fs-7 ">Select the SaleOrder.</div>

                        </div>

                        <div className="col-3 col-md-3 ">
                          <label className="form-label required">Invoice Date</label>
                          <div>
                            <Flatpickr
                              className='form-control'
                              placeholder='Pick date'
                              {...formik.getFieldProps('invoiceDate')}
                              name={'invoiceDate'}
                              options={{ dateFormat: 'Y-m-d' }}
                              onChange={([date1]) => {
                                dateChange(date1)
                               // setDateState({ date1 });
                                formik.setFieldValue('invoiceDate', date1)

                              }}

                            />
                          </div>

                          {/* {formik.touched.invoiceDate && formik.errors.invoiceDate && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.invoiceDate as string}</span> 
                              </div>
                            </div>
                          )} */}
                        </div>

                        <div className="col-3 col-md-3">
                          <label className="form-label">Due Date</label>
                          <div>
                            <Flatpickr
                              className='form-control'
                              placeholder='Pick date'
                              options={{ dateFormat: 'Y-m-d' }}
                              {...formik.getFieldProps('dueDate')}
                              name={'dueDate'}
                              value={formik.values.dueDate}
                              onChange={([date2]) => {
                                 //setDateState({ date2 });
                                 formik.setFieldValue('dueDate', date2)
 
                               }}
                            />
                          </div>
                        </div>
                      </div>
                      {customerData ? <div className='row'>
                        <h4 className=' mt-6 mb-3'>Customer Details:</h4>
                        <h5>{` Name: ${customerData.printName}  `}</h5>
                        {/* <div className='col-6'>
                          <h6>Billing Address</h6>
                          {customerData?.billingAddress ? (
                            <>
                              <p>{customerData.billingAddress.addressLine1}<br />
                                {customerData.billingAddress.addressLine2}<br />
                                {customerData.billingAddress.city}<br />
                                {customerData.billingAddress.zipCode}<br />
                                {customerData.billingAddress.faxNumber}</p>
                            </>
                          ) : (
                            <p>No Billing details are available</p>
                          )}
                        </div> */}

                        <div className='col-6'>
                          <span onClick={toggleBillingFormVisibility}>
                            <h6>
                              Billing Address{' '}
                              <a className='btn btn-light btn-active-light-primary btn-sm'>
                                <span className="ki-duotone ki-pencil">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </span>
                              </a>
                            </h6>
                          </span>
                          {isBillingFormVisible ? (
                            <><div className='row mb-4'>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> AddressLine1</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'billAddressLine1'}

                                >

                                </Field>
                              </div>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> AddressLine2</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'billAddressLine2'}

                                >
                                </Field>
                              </div>
                            </div><div className='row mb-7'>
                                <div className='col-6 col-md-6'>
                                  <label className='mb-2'> State</label>
                                  <Field
                                    className='form-select-solid'
                                    options={stateList}
                                    component={CustomSelect}
                                    name={'billStateId'}
                                  >
                                  </Field>
                                </div>
                                <div className='col-6 col-md-6'>
                                  <label className='mb-2'> City</label>
                                  <Field
                                    className='form-control'
                                    type='text'
                                    name={'billCityName'}
                                  >
                                  </Field>
                                </div>
                              </div></>) : <>
                            {customerData.billingAddress ? <p>{customerData.billingAddress.addressLine1}<br />
                              {customerData.billingAddress.addressLine2}<br />
                              {customerData.billingAddress.city}<br />
                              {customerData.billingAddress.zipCode}<br />
                              {customerData.billingAddress.faxNumber}</p> : <p>No Billing details are available</p>}
                          </>

                          }
                        </div>
                        <div className='col-6'>
                          <span onClick={toggleFormVisibility}>
                            <h6>
                              Shipping Address{' '}
                              <a className='btn btn-light btn-active-light-primary btn-sm'>
                                <span className="ki-duotone ki-pencil">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </span>
                              </a>
                            </h6>
                          </span>
                          {isFormVisible ? (
                            <><div className='row mb-4'>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> AddressLine1</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'shipAddressLine1'}

                                >

                                </Field>
                              </div>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> AddressLine2</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'shipAddressLine2'}

                                >
                                </Field>
                              </div>
                            </div><div className='row mb-7'>
                                <div className='col-6 col-md-6'>
                                  <label className='mb-2'> State</label>
                                  <Field
                                    className='form-select-solid'
                                    options={stateList}
                                    component={CustomSelect}
                                    name={'shipStateId'}
                                  >
                                  </Field>
                                </div>
                                <div className='col-6 col-md-6'>
                                  <label className='mb-2'> City</label>
                                  <Field
                                    className='form-control'
                                    type='text'
                                    name={'shipCityName'}
                                  >
                                  </Field>
                                </div>
                              </div></>) : <>
                            {customerData.shippingAddress ? <p>{customerData.shippingAddress.addressLine1}<br />
                              {customerData.shippingAddress.addressLine2}<br />
                              {customerData.shippingAddress.city}<br />
                              {customerData.shippingAddress.zipCode}<br />
                              {customerData.shippingAddress.faxNumber}</p> : <p>No Shipping details are available</p>}
                          </>

                          }
                        </div>
                      </div> : ''}

                      <div className='row mb-4 mt-5'>
                        <div className='col-4 col-md-4'>
                          <label>Place Of Supply</label>
                          <Field
                            className='form-select-solid'
                            options={placeOfSupply}
                            component={CustomSelect}
                            name={'placeOfSupplyId'}

                          >
                          </Field>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-7">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="kt_ecommerce_add_invoice_general" role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-7">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">
                        <h3>Item Details</h3>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <ProductSearchbar
                        onSelect={onProductsChange}
                      />
                    </div>
                    <div className='separator mb-8'></div>

                    <div className="card-body pt-0">
                      <div className="row mb-4 align-items-end">
                        <div className="col-lg-3 fv-row fv-plugins-icon-container">
                          <label className="form-label">Search Category</label>
                          <Select
                            name="categoryList"
                            className="react-select-container ms-1"
                            options={categoryList}
                            placeholder="Select a Category"
                            isMulti={false}
                            onChange={(selectedOption) => {
                              onCategoryChange(selectedOption)
                              setSelectedCategory(selectedOption?.value as number);
                            }}
                            value={categoryList.find(option => option.value === selectedCategory) || null}
                          />
                        </div>
                        <div className="col-lg-3 fv-row fv-plugins-icon-container">
                          <label className="form-label">Search product group</label>
                          <Select
                            name="categoryList"
                            className="react-select-container ms-1"
                            options={productGroupList}
                            placeholder="Select a ProductGroup"
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedProductGroup(selectedOption?.value as number);
                            }}
                            value={productGroupList.find(option => option.value === selectedProductGroup) || null}

                          />
                        </div>


                        <div className="col-lg-3 fv-row fv-plugins-icon-container">
                          <label className="form-label">Search Polishing Type</label>
                          <Select
                            className=
                            'form-select-solid ms-1'
                            value={polishingTypeList.find(option => option.value === polishId) || null}
                            name="polishIds"
                            options={polishingTypeList}
                            onChange={(selectedPT) => handlePolishingChange(selectedPT)}
                            placeholder="Select PolishingType"
                          ></Select>
                        </div>
                        <div className="col-lg-1 fv-row fv-plugins-icon-container">
                          <button type='button' className="btn btn-primary mt-6" onClick={handleSearch}>
                            Search
                          </button>
                        </div>
                      </div>

                    </div>

                    <div className="card-body pt-0">

                      {colorList.length > 0 ?
                        <><div className='opt1 relative-options show-opt'>
                          <table className="color-tbl">
                            <tbody>
                              <tr>
                                {colorList.map((color, index) => (
                                  <td key={index}>
                                    <div className="form-group">
                                      <label className="clr-label">{color.colorName}
                                        <input type="number" className="color-opt form-color" value={quantities[color.colorId] || ""}
                                          onChange={(e) => handleQuantityChange(color.colorId, parseInt(e.target.value))} /> </label>
                                    </div>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>

                          <div className='text-right'>

                            <button type='button' className="btn btn-primary me-5" onClick={
                              handleAddClick
                            }
                            >
                              Add
                            </button>
                            <button type='button' className="btn btn-primary" onClick={clearFilters}>
                              Clear
                            </button>
                          </div>

                        </>
                        : null}
                    </div>

                    <div className="card-body pt-0">
                      <div className='row pb-5' >
                        <div className="col-2 col-md-2"> <label htmlFor='kt_create_products_form_products_productname' className='text-gray-400 fs-5 fw-semibold mb-3'>Product  </label></div>
                        <div className="col-2 col-md-2"> <label htmlFor='kt_create_products_form_products_quantity' className='text-gray-400 fs-5 fw-semibold mb-3'>Quantity  </label></div>
                        <div className="col-2 col-md-2"> <label htmlFor='kt_create_products_form_products_unitprice' className='text-gray-400 fs-5 fw-semibold mb-3'>Unit Price  </label></div>
                        <div className="col-2 col-md-2"> <label htmlFor='kt_create_products_form_products_discountpercent' className='text-gray-400 fs-5 fw-semibold mb-3'>Discount Percent ( % ) </label></div>
                        <div className="col-2 col-md-2"> <label htmlFor='kt_create_products_form_products_taxpercent' className='text-gray-400 fs-5 fw-semibold mb-3'>Tax Percent ( % ) </label></div>
                        <div className="col-1 col-md-1"> <label htmlFor='kt_create_products_form_products_total' className='text-gray-400 fs-5 fw-semibold mb-3'> Total ( ₹ ) </label></div>
                        <div className="col-1 col-md-1 text-center"> <label htmlFor='kt_create_products_form_products_actions' className='text-gray-400 fs-5 fw-semibold mb-3'>Actions </label></div>
                      </div>

                      <div>
                        {saleOrderItems.filter(t => !t.isDeleted).map((soitem, index) => (
                          <div className='row pb-4' key={soitem.rowNumber}>
                            <div className="col-2 col-md-2">
                              <p>{soitem.productName}</p>

                            </div>


                            <div className="col-2 col-md-2">
                              <Field
                                type='text'
                                className={clsx('form-control')}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      saleOrderItem.quantity = e.target.value;
                                      updateItemTotal(soitem.rowNumber as number);


                                    }
                                  });
                                }}
                                name={`saleOrderItemList[${soitem.rowNumber}].quantity`}
                                value={saleOrderItems[soitem.rowNumber as number].quantity}
                              />
                            </div>
                            <div className="col-2 col-md-2">
                              <Field
                                type='text'
                                className={clsx('form-control')}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      saleOrderItem.productPrice = e.target.value;
                                      updateItemTotal(soitem.rowNumber);
                                    }
                                  });
                                }}
                                name={`saleOrderItemList[${soitem.rowNumber}].productPrice`}
                                value={saleOrderItems[soitem.rowNumber].productPrice}
                              />
                            </div>
                            <div className="col-2 col-md-2 mb-3">
                              <Field


                                type='number'
                                className={clsx('form-control')}
                                name={`saleOrderItemList[${soitem.rowNumber}].discountPercent`}
                                value={saleOrderItems[soitem.rowNumber].discountPercent}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      if (e.target.value >= 0 && e.target.value <= 100) {
                                        saleOrderItem.discountPercent = e.target.value;
                                        updateItemTotal(soitem.rowNumber);
                                      }

                                    }
                                  });
                                }}
                              />
                              <span className='mt-3'> Disc.amt: ( ₹ ){saleOrderItems[soitem.rowNumber].discountAmount?.toFixed(2)}</span>
                            </div>
                            <div className="col-2 col-md-2 mb-3">

                              {/* <label className='form-control'>{saleOrderItems[soitem.rowNumber].taxPercent?.toFixed(2)}</label> */}
                              <Field


                                type='number'
                                className={clsx('form-control')}
                                name={`saleOrderItemList[${soitem.rowNumber}].taxPercent`}
                                value={saleOrderItems[soitem.rowNumber].taxPercent}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      if (e.target.value >= 0 && e.target.value <= 100) {
                                        saleOrderItem.taxPercent = e.target.value;
                                        updateItemTotal(soitem.rowNumber);
                                      }

                                    }
                                  });
                                }}
                              />
                              <span className='mt-3'> Tax.amt: ( ₹ ){saleOrderItems[soitem.rowNumber].taxAmount?.toFixed(2)}</span>
                            </div>


                            <div className="col-1 col-md-1 mb-3">
                              <Field
                                type='number'
                                className={clsx('form-control')}
                                name={`saleOrderItemList[${soitem.rowNumber}].totalInclusiveTax`}
                                value={saleOrderItems[soitem.rowNumber].totalInclusiveTax === null ? saleOrderItems[soitem.rowNumber].total : saleOrderItems[soitem.rowNumber].totalInclusiveTax}
                                disabled={true}
                              />
                            </div>
                            <div className="col-1 col-md-1 mb-3 text-center">
                              <button
                                type='button'
                                data-repeater-delete=''
                                className='btn btn-sm btn-icon btn-light-danger mt-2'
                                onClick={(e: any) => {
                                  handleDelete(e, soitem.rowNumber)
                                }}
                              >
                                <i className='ki-duotone ki-cross fs-1'>
                                  <span className='path1' />
                                  <span className='path2' />
                                </i>
                              </button>
                            </div>
                          </div>
                        ))}


                      </div>
                      <div className='separator mb-7'></div>
                      <div className='row mt-4'>
                        <div className='col-6 col-md-6 mb-3'>

                        </div>
                        <div className='col-xl-6 col-lg-12 order-xl-2 col-md-12 mb-3'>
                          <div className='px-5 py-2 bg-light text-dark border'>
                          <div className='d-flex justify-content-between mb-2'>
                            <label className='fs-4 mt-3'>Sub Total</label>
                            <span className='fs-4 mt-3 '>
                              ₹ {formik.values.orderSubTotal?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </span>

                          </div>
                          <div className='separator mb-2'></div>

                          {otherCharges
                            .filter(t => !t.isDeleted)
                            .map((ocitem, index) => (
                              <><div className='row mb-3 align-items-center'
                                key={ocitem.rowNumber}
                              >
                                <div className='col-4'>
                                  <Select
                                    options={chargesList}
                                    name={`otherCharges[${ocitem.rowNumber}].label`}
                                    value={otherCharges.find(x => x.label === otherCharges[ocitem.rowNumber].label)}
                                    onChange={(newValue: any) => {
                                      if (newValue === null) {
                                        setOtherCharges((oc) => {
                                          oc[ocitem.rowNumber].label = '';
                                        })
                                      }
                                      else {
                                        setOtherCharges((oc) => {
                                          oc[ocitem.rowNumber].label = newValue.label;
                                        })
                                      }
                                    }}
                                  />
                                </div>


                                <div className='col-3 ps-0'>
                                  <div className="input-group">
                                    {/* <Field className=''
                                      options={sign}
                                      component={CustomSelect}
                                      name={`otherCharges[${ocitem.rowNumber}].sign`}
                                      onDropDownChange={(e: any) => {
                                        setOtherCharges((oc) => {
                                          oc[ocitem.rowNumber].sign = e.value;
                                        })
                                      }}
                                    /> */}

                                    <Field
                                      type='number'
                                      name={`otherCharges[${ocitem.rowNumber}].value`}
                                      className={clsx('form-control text-right')}
                                      onChange={(e: any) => {
                                        const newvalue = parseFloat(e.target.value)
                                        handleOtherChargeValueChange(index, isNaN(newvalue) ? 0 : newvalue);
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <span className='alert-danger'>{errorMessages[index]}</span>
                                  </div>
                                </div>

                                <div className='col-1 ps-0'>
                                <div className="d-flex align-items-center gap-2">
                                    <i className="bi bi-question-circle-fill" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"></i>
                                    <span className="text-danger cursor-pointer"onClick={(e: any) => {
                                      setOtherCharges((oc) => {
                                        oc[ocitem.rowNumber].isDeleted = true;
                                      })
                                    }}>X</span>
                                  </div>
                                  
                                </div>

                                <div className="col-4 text-right">₹ 63,600.00</div>

                              </div></>
                            ))}
                          <div className='form-group mt-5 mb-2'>
                            <button type='button'

                              className='btn btn-sm btn-light-primary'
                              onClick={handleAddRow}>
                              + Add another Charges
                            </button>
                          </div>
                          <div className='d-flex justify-content-between mb-2'>

                            <label className='fs-4 mt-3'>Discount</label>
                            <span className='fs-4 mt-3 '>₹ {totalDiscountAmount()}</span>

                            {/* <span className='fs-4 mt-3 '>₹ {formik.values.totalDiscountedPrice.toFixed(2)}</span> */}
                          </div>

                          <div className='d-flex justify-content-between mb-2'>

                            <label className='fs-4 mt-3'>Tax Amount</label>
                            <span className='fs-4 mt-3 '>₹ {totalTaxAmount()}</span>
                          </div>

                          <div className='d-flex justify-content-between mb-2'>
                            <label className='fs-4 mt-3'>Round Off</label>

                            <span className='fs-4 mt-3 '>
                              {((formik.values.roundOff !== undefined && !Number.isNaN(parseInt(formik.values.roundOff.toString()))) ? formik.values.roundOff?.toFixed(2) : '0.0')}
                              {/* {formik.values.roundOff?.toFixed(2)} */}
                            </span>
                          </div>

                          <div className='separator mb-2'></div>
                          <div className='d-flex justify-content-between mb-2'>
                            <label className='fs-4 mt-3'>Total</label>
                            <span className='fs-4 mt-3 '>₹
                              {/* {formik.values.orderTotal?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                              {((formik.values.orderTotal !== undefined && !Number.isNaN(parseInt(formik.values.orderTotal.toString()))) ? formik.values.orderTotal?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}



                            </span>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>


            <div className='card card-flush'>
              <div className='card-body'>
                <div className='d-flex justify-content-end'>
                  <button
                    type='reset'
                    className='btn btn-light me-3 border'
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1"
                    disabled={formik.isSubmitting}
                  >
                    Discard
                  </button>
                  <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h2>Confirmation</h2>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="w-100">

                            {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
                            <i className="las la-exclamation-circle"></i>
                          </div> */}

                            <div className="fv-row text-center">
                              <h5>Are you sure you want to discard ?</h5>
                            </div>

                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => navigate(-1)}
                          >
                            Yes
                          </button>
                          <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-color-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Submit</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

          </div>



        </div>
      </form>

      {(formik.isSubmitting) && <Loading />}
    </FormikProvider>
  )
}

export { InvoiceAddPage }



{/* <div className="card-body pt-0">
                      <div className='row mb-4'>
                        <label className='form-label'></label>
                        <div className='form-check form-check-custom form-check-solid ms-1'>
                          <input
                            className='form-check-input ms-1'
                            type='checkbox'
                            checked={paymentReceived}
                            onChange={paymentReceive}

                          /><span className='ms-4'>I have received the payment</span>
                        </div>
                      </div>
                      {paymentReceived ?
                        <div className='row me-4 mb-8'>
                          <div className='col-6 col-md-6'>
                            <label className='form-label required'>Payment Mode</label>
                            <Field
                              className="form-select-solid"
                              // options={tenantType}
                              component={CustomSelect}

                            ></Field>
                          </div>
                          <div className='col-6 col-md-6'>
                            <label className='form-label required'>Deposit To</label>
                            <Field
                              className="form-select-solid"
                              // options={bankAccount}
                              component={CustomSelect}

                            ></Field>
                          </div>
                        </div>
                        : ''}
                    </div> */}
