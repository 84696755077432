import React, { useEffect, useState } from 'react'
import { StatusOptions } from '../../../../../core/models/StatusOptions';

import Select from 'react-select'
import { SelectOptionProps } from '../../../../../core/models/SelectOptionProps';
import { Filter, PaginationFilter, initialQueryState, useDebounce } from '../../../../../../_metronic/helpers';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { AgentListToolbar } from './AgentListToolbar';


const AgentAdvancedSearch = () => {
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);

    const { state, updateState } = useQueryRequest()
    const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [filterName, updateFilterName] = useState<string>('')
    const [filterMobileNumber, updateFilterMobileNumber] = useState<string>('')
    const [filterEmail, updateFilterEmail] = useState<string>('')
    const [filterCompany, updateFilterCompany] = useState<string>('')


    const updateSearchFilters = () => {
        let filters: Filter[] = []
        if (filterStatus != undefined) {
            filters.push({
                field: 'isActive',
                operator: 'eq',
                value: filterStatus
            })
        }

        if (filterName != undefined && filterName != '') {
            filters.push({
                field: 'firstName',
                operator: 'contains',
                value: filterName
            })
        }

        if (filterMobileNumber != undefined && filterMobileNumber != '') {
            filters.push({
                field: 'mobileNumber',
                operator: 'contains',
                value: filterMobileNumber
            })
        }

        if (filterEmail != undefined && filterEmail != '') {
            filters.push({
                field: 'emailAddress',
                operator: 'contains',
                value: filterEmail
            })
        }

        if (filterCompany != undefined && filterCompany != '') {
            filters.push({
                field: 'companyName',
                operator: 'contains',
                value: filterCompany
            })
        }

        if (filters.length > 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: {
                    filters: filters,
                    logic: 'and'
                }, ...initialQueryState
            })
        }

        else if (filters.length === 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: filters[0],
                ...initialQueryState
            })
        }
        else {
            updateState({
                keyword: searchTerm,
                advancedFilter: undefined,
                ...initialQueryState
            })
        }
    }

    return (
        <>

            <div className="card mb-7">
                <div className='card-header'>
                    <div className='card-title justify-content-between w-100'>
                        <h3>Agent Filter</h3>
                        <button
                                className="btn btn-light btn-active-light-primary border"
                                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                            >
                               <i className="ki-duotone ki-filter fs-1 text-muted me-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </button>
                    </div>
                </div>
                {/* <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="input-group">
                                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input
                                    type="text"
                                    className="form-control form-control-solid ps-10"
                                    id="txtsearchText"
                                    placeholder="Search Agent"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <button
                                className="btn btn-light btn-active-light-primary border"
                                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                            >
                               <i className="ki-duotone ki-filter fs-1 text-muted me-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </button>
                        </div>
                        <div className='col-lg-2 '>

                            <AgentListToolbar />

                        </div>


                    </div>


                    

                </div> */}

                {advancedSearchVisible && (
                     <>

                <div className='card-body'>
                <>
                            <div className="collapse" id="kt_advanced_search_form">
                                <div className="separator separator-dashed mt-9 mb-6"></div>
                            </div>


                            <div className="row g-5">

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Name
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Agent Name" autoComplete='off' name="firstName"
                                        value={filterName} onChange={(e) => updateFilterName(e.target.value)} />
                                </div>

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Mobile Number
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Mobile Number" autoComplete='off' name="mobileNumber"
                                        value={filterMobileNumber} onChange={(e) => updateFilterMobileNumber(e.target.value)} />
                                </div>

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Email Address
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Email" autoComplete='off' name="emailAddress"
                                        value={filterEmail} onChange={(e) => updateFilterEmail(e.target.value)} />
                                </div>

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Company Name
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Company" autoComplete='off' name="companyName"
                                        value={filterCompany} onChange={(e) => updateFilterCompany(e.target.value)} />
                                </div>

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Status
                                    </label>
                                    <Select
                                        className='form-select-solid fw-bolder'
                                        name="statusId"
                                        options={StatusOptions}
                                        placeholder="Select Status"
                                        onChange={(e) => {
                                            if (e != null) {
                                                updateFilterStatus(e?.value === 1)
                                            }
                                            else {
                                                updateFilterStatus(undefined)
                                            }
                                        }}
                                        isClearable={true}
                                    ></Select>
                                </div>
                            </div>
                        </>
                </div>

                <div className='card-footer text-end'>
                    <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                        Search
                    </button>
                </div>

                </>

                )}

            </div>
        </>
    );

}

export default AgentAdvancedSearch