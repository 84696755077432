import axios, { AxiosResponse } from 'axios'
import { Category, CategoryQueryResponse } from './_models'
import { ID, PaginationFilter } from "../../../../../../_metronic/helpers/crud-helper/models"
import { Result } from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Category_BY_ID_URL = `${API_URL}/category`
const Category_ADD_URL = `${API_URL}/category`
const Category_LIST_URL = `${API_URL}/category/search`
const getCategoryList = (filter: PaginationFilter): Promise<CategoryQueryResponse> => {
  return axios
    .post(`${Category_LIST_URL}`, filter)
    .then((d: AxiosResponse<CategoryQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getCategoryById = async (id: ID): Promise<Result | Category> => {
  return axios
    .get(`${Category_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Category>) => response.data)
    .then((response: Category) => response)
    .catch((err: Result) => {
      return err
    })
}
const createCategory = async (category: Category): Promise<Result> => {
  return await axios
    .post(Category_ADD_URL, {
      name: category.name?.trim(),
      printName: category.printName?.trim(),
      parentCategoryId: category.parentCategoryId === 0 ? undefined : category.parentCategoryId,
      isParent: category.isParent,
      uploadFile: category.uploadFile,
      hsnCode: category.hsnCode,
      taxRateId: category.taxRateId,      
      isFeatured:category.isFeatured,
      uploadBannerImage:category.uploadBannerImage,
      sortNumber:category.sortNumber

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateCategory = (category: Category): Promise<Result> => {
  return axios
    .put(`${Category_BY_ID_URL}/${category.id}`, {
      id: category.id,
      name: category.name?.trim(),
      printName: category.printName?.trim(),
      parentCategoryId: category.parentCategoryId === 0 ? undefined : category.parentCategoryId,
      isActive: category.isActive,
      uploadFile: category.uploadFile,
      hsnCode: category.hsnCode,
      taxRateId: category.taxRateId,
      isParent: category.isParent, 
      isFeatured:category.isFeatured,
      uploadBannerImage:category.uploadBannerImage,
      imagePath:category.imagePath,
      bannerImagePath:category.bannerImagePath,
      sortNumber:category.sortNumber



    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteCategory = async (id: ID): Promise<Result | Category> => {
  return axios
    .delete(`${Category_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Category>) => response.data)
    .then((response: Category) => response)
    .catch((err: Result) => {
      return err
    })
}
export {

  getCategoryById,
  getCategoryList,
  createCategory,
  updateCategory,
  deleteCategory

}
