import { Route, Routes, Outlet, Navigate } from 'react-router-dom'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { PaymentModeWrapper } from './pages/PaymenModeList'

const Breadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Settings',
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
]

const PaymentModePage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='list'
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>PaymentMode </PageTitle>
                            <PaymentModeWrapper />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default PaymentModePage
