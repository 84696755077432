import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, useFormik, FormikProvider } from 'formik'
import { ProductGroup, initialProductGroup, productImagesItems } from '../core/_models'
import { Result } from '../../../../../core/models/Result'
import { camelize } from '../../../../../core/utils/StringHelpers'
import { KTSVG, PaginationFilter, initialQueryState, isNotEmpty } from '../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Loading } from '../../../../../core/shared/components/Loading'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { useImmer } from 'use-immer';
import { createProductGroup } from '../core/_requests'
import { SelectOptionProps } from '../../../../../core/models/SelectOptionProps'
import { getCategoryList } from '../../../category/category-list/core/_requests'
import { Category } from '../../../category/category-list/core/_models'
import CustomSelect from '../../../../../core/shared/components/CustomSelect'
import UploadImage from '../../../../../core/shared/components/UploadImage'
import { ImageModel } from '../../../../../core/models/ImageModel'
import { getBrandList } from '../../../brand/brand-list/core/_requests'
import { getManufacturerList } from '../../../manufacturer/maufacturer-list/core/_requests'
import { Brand } from '../../../brand/brand-list/core/_models'
import { Manufacturer } from '../../../manufacturer/maufacturer-list/core/_models'
import { getDesignNumberList } from '../../../../../core/shared/core/_request'
import { DesignNumber } from '../../../../../core/shared/core/_model'
import CustomAsyncSelect from '../../../../../core/shared/components/CustomAsyncSelect'
import { Vendor } from '../../../../purchases/vendor/vendor-list/core/_models'
import { getVendorList } from '../../../../purchases/vendor/vendor-list/core/_requests'
type Props = {
  isProductLoading: boolean
}
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    field: 'isActive',
    operator: 'eq',
    value: true,
  },
}
const ProductSchema = Yup.object().shape({
  name: Yup.string().matches(/^[A-Za-z0-9-_', .]+$/, "Only alphanumneric, _,-,' are allowed for this field ")
    .required('Please enter ProductGroup'),
  categoryId: Yup.number().required('Please select category'),
  description: Yup.string(),
  isActive: Yup.bool(),
  designNumberId: Yup.string().required('Please select design number'),
  manufacturerId: Yup.string().required('Please selectmanufacturer'),
  brandId: Yup.string().required('Please select the brand'),
  spSemiWholeSeller: Yup.number().required('Please enter selling price').positive('Please enter valid price'),
  spRetail: Yup.number().required('Please enter selling price').positive('Please enter valid price'),
  spWholeSeller: Yup.number().required('Please enter selling price').positive('Please enter valid price'),
  retailMoQ: Yup.number().required('Please enter minimum order quantity').positive('Please enter positive MOQ').integer('Please enter a whole number'),
  wholeSellerMoQ: Yup.number().required('Please enter minimum order quantity').positive('Please enter positive MOQ').integer('Please enter a whole number'),
  semiWholeSellerMoQ: Yup.number().required('Please enter minimum order quantity').positive('Please enter positive MOQ').integer('Please enter a whole number'),
  //productGroupImage:Yup.mixed().required('Please upload image'),
  productImagePath: Yup.mixed().required('Please upload an image'),
  purchasePrice:Yup.number().positive()

})
const ProductGroupAddPage: FC<Props> = ({ isProductLoading }) => {
  const [selectedImage, setSelectedImage] = useState<ImageModel>({})
  const [brandList, setBrandList] = useImmer<SelectOptionProps[]>([]);
  const [manufacturerList, setManufacturerList] = useImmer<SelectOptionProps[]>([])
  const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([])
  const [secondCategoryList, setSecondCategoryList] = useState<SelectOptionProps[]>([])
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState<number>()

  const formik = useFormik({
    initialValues: initialProductGroup,
    validationSchema: ProductSchema,
    onSubmit: async (formValues, { setFieldError, setSubmitting }) => {

      setSubmitting(true)
      try {

        let result: Result

        let productData: ProductGroup = {
          name: formValues.name,
          description: formValues.description,
          categoryId: formValues.categoryId,
          spSemiWholeSeller: formValues.spSemiWholeSeller,
          spRetail: formValues.spRetail,
          spWholeSeller: formValues.spWholeSeller,
          retailMoQ: formValues.retailMoQ,
          wholeSellerMoQ: formValues.wholeSellerMoQ,
          semiWholeSellerMoQ: formValues.semiWholeSellerMoQ,
          brandId: formValues.brandId,
          manufacturerId: formValues.manufacturerId,
          secondaryCategory: formValues.secondaryCategory,
          purchasePrice: formValues.purchasePrice,
          vendorId: formValues.vendorId,
          designNumberId: formValues.designNumberId,
          productImagePath: ''

        }
        if (isNotEmpty(selectedImage.data)) {
          productData.productGroupImage = selectedImage;
        }
        result = await createProductGroup(productData)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('ProductGroup saved successfully!')
          navigate('/catalog/productgroup/list')
        } else {
          if (result.statusCode === 400 || result.statusCode === 500) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage),
              toast.error(result.propertyResults.map(error => error.errorMessage))
            )
          }
        }
      } catch (ex) {
        console.error(ex)
        toast.error('An error occurred while saving the Productgroup.')
      }
    },
  })

  const navigate = useNavigate()
  const { state, updateState } = useQueryRequest()
  const filterStatus = (value: number | undefined) => {
    if (state.advancedFilter != undefined && value === undefined) {
      updateState({ advancedFilter: undefined, ...initialQueryState })
    } else {
      if (value !== undefined) {
        updateState({
          advancedFilter: {
            field: 'isActive',
            operator: 'eq',
            value: value == 1,
          },
          ...initialQueryState,
        })
      }
    }
  }

  // const [selectedImage, setSelectedImage] = useImmer<productImagesItems>({
  //   name: '',
  //   extension: '',
  //   data: '',
  //   isImageDelete: false,
  //   isFeatured: true
  // })


  let result: any
  let categoryArray: any[] = []
  const updatedPaginationFilter: PaginationFilter = {
    ...paginationFilter,
    advancedFilter: {
      field: 'isActive',
      operator: 'eq',
      value: true,
    },

  };
  useEffect(() => {

    getCategoryList(updatedPaginationFilter).then((v) => {
      result = v.data as Category[]
      result.map((item: any) => {
        return categoryArray.push({ value: item.id, label: item.parentChildCategoryName })
      })
      setCategoryList(categoryArray)
    })

    getBrandList(paginationFilter).then((v) => {
      result = v.data as Brand[]
      let brandArray: any[] = []
      result.map((item: any) => {
        return brandArray.push({ value: item.id, label: item.name })
      })
      setBrandList(brandArray)
    })
    getManufacturerList(paginationFilter).then((v) => {
      result = v.data as Manufacturer[]
      let manufacturerArray: any[] = []
      result.map((item: any) => {
        return manufacturerArray.push({ value: item.id, label: item.name })
      })
      setManufacturerList(manufacturerArray)
    })

  }, [])

  useEffect(() => {
    const secondaryCategory = categoryList
      .filter((category: SelectOptionProps) => category.value !== selectedPrimaryCategory)
      .map((item: SelectOptionProps) => ({
        value: item.value as number,
        label: item.label as string
      }));
    setSecondCategoryList(secondaryCategory);
  }, [selectedPrimaryCategory])


  return (
    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_product_form'
        className='form fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column flex-lg-row '
          id='kt_modal_add_product_scroll'
        >
          <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header justify-content-center'>
                <div className='card-title'>
                  <h3>Product Group Image</h3>
                </div>
              </div>
              <div className="card-body text-center">
              <input type="hidden" {...formik.getFieldProps('productImagePath')}></input>
              <UploadImage
                HandleUpload={(img: ImageModel) => {
                  setSelectedImage(img)
                  formik.setFieldValue('productImagePath', img.name)

                }}
                HandleRemove={() => { setSelectedImage({}) }} />
              <div className='fv-plugins-message-container'>
                {formik.touched.productImagePath && formik.errors.productImagePath && (
                  <div className='fv-help-block text-center'>
                    <span role='alert '>{formik.errors.productImagePath}</span>
                  </div>
                )}
              </div>

              </div>
            </div>

            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Product Details</h3>
                </div>
              </div>
              <  div className='card-body pt-0'>
                <label className='form-label required'>Primary Category</label>
                <Field
                  {...formik.getFieldProps('categoryId')}
                  className={clsx(
                    'form-select-solid',
                    { 'is-invalid': formik.touched.categoryId && formik.errors.categoryId },
                    {
                      'is-valid': formik.touched.categoryId && !formik.errors.categoryId,
                    }
                  )}
                  component={CustomSelect}
                  options={categoryList}
                  placeholder='Select Category'
                  name='categoryId'
                  onDropDownChange={(e: { value: any }) => {
                    setSelectedPrimaryCategory(e.value)
                    formik.setFieldValue('categoryId', e?.value)
                  }}
                ></Field>
                {formik.touched.categoryId && formik.errors.categoryId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.categoryId}</span>
                    </div>
                  </div>
                )}
                <div className='text-muted fs-7 mt-2'>Add product to a category.</div>
              </div>
              <div className='card-body pt-0'>
                <label className='form-label '>Secondary Category</label>

                <Field
                  {...formik.getFieldProps('secondaryCategory')}
                  name="secondaryCategory"
                  className={clsx(
                    'form-select-solid',
                    { 'is-invalid': formik.touched.secondaryCategory && formik.errors.secondaryCategory },
                    {
                      'is-valid': formik.touched.secondaryCategory && !formik.errors.secondaryCategory,
                    }
                  )}
                  options={secondCategoryList}
                  component={CustomSelect}
                  placeholder="Select "
                  isMulti={true}
                />
                {formik.touched.secondaryCategory && formik.errors.secondaryCategory && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.secondaryCategory}</span>
                    </div>
                  </div>
                )}

              </div>
            </div>

          </div>

          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10 '>
            <div className='tab-content'>
              <div className='tab-pane fade active show' id='product-general-tab' role='tab-panel'>
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Add Product Group</h3>
                    </div>
                  </div>
                    <div className='card-body'>
                      <div className='row mb-4'>

                        <div className='col-6 col-md-6'>
                          <label className='required form-label'>Design Number</label>
                          <Field
                            className='form-select-solid'
                            component={CustomAsyncSelect<DesignNumber>}
                            {...formik.getFieldProps('designNumberId')}
                            name={'designNumberId'}

                            loadOptionFunc={getDesignNumberList}
                            searchByField="name"
                            labelField="name"
                            onDropDownChange={(e: { value: any, label: any }) => {

                              formik.setFieldValue('designNumberId', e?.value)
                              formik.setFieldValue('name', e?.label)
                              
                            }}

                          ></Field>

                          {formik.touched.designNumberId && formik.errors.designNumberId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.designNumberId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-6'>
                          <label className='required form-label'>Product Group Name</label>
                          <input
                            type='text'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.name && formik.errors.name },
                              {
                                'is-valid': formik.touched.name && !formik.errors.name,
                              }
                            )}
                           
                            {...formik.getFieldProps('name')}
                            name='name'
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.name}</span>
                              </div>
                            </div>
                          )}
                        </div>




                      </div>

                      <div className='row mb-4'>
                        <div className='col-6 col-md-6'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>Brand</label>

                          <Field
                            className={clsx(
                              'form-select-solid ms-1',
                              { 'is-invalid': formik.touched.brandId && formik.errors.brandId },
                              {
                                'is-valid': formik.touched.brandId && !formik.errors.brandId,
                              }
                            )}
                            component={CustomSelect}
                            options={brandList}
                            placeholder='Select brand'
                            id='brandId'
                            name='brandId'
                            onChange={(e: { value: any }) =>
                              formik.setFieldValue('brandId', e?.value)
                            }
                          ></Field>
                          {formik.touched.brandId && formik.errors.brandId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.brandId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-6 col-md-6'>
                          {/* <!--begin::Label--> */}
                          <label className='required form-label'>Manufacturer</label>

                          <Field
                            className={clsx(
                              'form-select-solid ms-1',
                              { 'is-invalid': formik.touched.manufacturerId && formik.errors.manufacturerId },
                              {
                                'is-valid': formik.touched.manufacturerId && !formik.errors.manufacturerId,
                              }
                            )}
                            component={CustomSelect}
                            options={manufacturerList}
                            {...formik.getFieldProps('manufacturerId')}
                            id='manufacturerId'
                            name='manufacturerId'
                            onChange={(e: { value: any }) =>
                              formik.setFieldValue('manufacturerId', e?.value)
                            }
                          ></Field>
                          {formik.touched.manufacturerId && formik.errors.manufacturerId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.manufacturerId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-4'>

                        <div className='col-6 col-md-6'>
                          <label className='form-label'>Purchase  Price</label>

                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.purchasePrice && formik.errors.purchasePrice },
                              {
                                'is-valid': formik.touched.purchasePrice && !formik.errors.purchasePrice,
                              }
                            )}
                            {...formik.getFieldProps('purchasePrice')}
                            name='purchasePrice'
                          />
                          {formik.touched.purchasePrice && formik.errors.purchasePrice && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.purchasePrice}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-6'>
                          <label className='form-label'>Default Vendor</label>
                          <Field
                            className='form-select-solid-solid'
                            component={CustomAsyncSelect<Vendor>}
                            {...formik.getFieldProps(`vendorId`)}
                            name={`vendorId`}
                            loadOptionFunc={getVendorList}
                            searchByField="printName"
                            labelField="printName"
                            onChange={(newValue: any) => {

                              formik.setFieldValue('vendorId', newValue?.value)

                            }}
                          ></Field>
                          {formik.touched.vendorId && formik.errors.vendorId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.vendorId}</span>
                              </div>
                            </div>
                          )}
                        </div>




                      </div>
                      <div className='row'>
                        <div className='col-12'>
                        <label className='form-label'>Description</label>
                        <textarea
                          rows={3}
                          className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            { 'is-invalid': formik.touched.description && formik.errors.description },
                            {
                              'is-valid': formik.touched.description && !formik.errors.description,
                            }
                          )}
                          disabled={formik.isSubmitting || isProductLoading}
                          placeholder='Description'
                          {...formik.getFieldProps('description')}
                          name='description'
                        />
                        {formik.touched.description && formik.errors.description && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.description}</span>
                            </div>
                          </div>
                        )}
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className='tab-content'>
              <div className='tab-pane fade active show' id='product-general-tab' role='tab-panel'>
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Finance</h3>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='mb-5 row '>
                        <div className='col-4 col-md-4'></div>
                        <div className='required col-4 col-md-4 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          SELLING PRICE
                        </div>
                        <div className='required col-4 col-md-4  text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          MINIMUM ORDER QUANTITY
                        </div>
                      </div>
                      <div className='separator mb-3'></div>
                      <div className='mb-5 row '>
                        <div className='col-4 col-md-4 mb-2'>
                          <label className='text-gray-800 fs-5 fw-bold'>Wholeseller</label>
                        </div>
                        <div className='col-4 col-md-4 mb-2 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.spWholeSeller && formik.errors.spWholeSeller },
                              {
                                'is-valid': formik.touched.spWholeSeller && !formik.errors.spWholeSeller,
                              }
                            )}
                            {...formik.getFieldProps('spWholeSeller')}
                            name='spWholeSeller'
                          />
                          {formik.touched.spWholeSeller && formik.errors.spWholeSeller && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.spWholeSeller}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-4 col-md-4 mb-2 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.wholeSellerMoQ && formik.errors.wholeSellerMoQ },
                              {
                                'is-valid': formik.touched.wholeSellerMoQ && !formik.errors.wholeSellerMoQ,
                              }
                            )}
                            {...formik.getFieldProps('wholeSellerMoQ')}
                            name='wholeSellerMoQ'
                          />
                          {formik.touched.wholeSellerMoQ && formik.errors.wholeSellerMoQ && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.wholeSellerMoQ}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='separator mb-3'></div>
                      <div className='mb-5 row '>
                        <div className='col-4 col-md-4 mb-2'>
                          <label className='text-gray-800 fs-5 fw-bold'>Semi-Wholeseller</label>
                        </div>
                        <div className='col-4 col-md-4 mb-2 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.spSemiWholeSeller && formik.errors.spSemiWholeSeller },
                              {
                                'is-valid': formik.touched.spSemiWholeSeller && !formik.errors.spSemiWholeSeller,
                              }
                            )}
                            {...formik.getFieldProps('spSemiWholeSeller')}
                            name='spSemiWholeSeller'
                          />
                          {formik.touched.spSemiWholeSeller && formik.errors.spSemiWholeSeller && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.spSemiWholeSeller}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-4 col-md-4 mb-2 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.semiWholeSellerMoQ && formik.errors.semiWholeSellerMoQ },
                              {
                                'is-valid': formik.touched.semiWholeSellerMoQ && !formik.errors.semiWholeSellerMoQ,
                              }
                            )}
                            {...formik.getFieldProps('semiWholeSellerMoQ')}
                            name='semiWholeSellerMoQ'
                          />
                          {formik.touched.semiWholeSellerMoQ && formik.errors.semiWholeSellerMoQ && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.semiWholeSellerMoQ}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='separator mb-3'></div>
                      <div className='mb-5 row '>
                        <div className='col-4 col-md-4 mb-2'>
                          <label className='text-gray-800 fs-5 fw-bold'>Retail</label>
                        </div>
                        <div className='col-4 col-md-4 mb-2 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.spRetail && formik.errors.spRetail },
                              {
                                'is-valid': formik.touched.spRetail && !formik.errors.spRetail,
                              }
                            )}
                            {...formik.getFieldProps('spRetail')}
                            name='spRetail'
                          />
                          {formik.touched.spRetail && formik.errors.spRetail && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.spRetail}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-4 col-md-4 mb-2 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <input
                            type='number'
                            className={clsx(
                              'form-control form-control-solid mb-3 mb-lg-0',
                              { 'is-invalid': formik.touched.retailMoQ && formik.errors.retailMoQ },
                              {
                                'is-valid': formik.touched.retailMoQ && !formik.errors.retailMoQ,
                              }
                            )}
                            {...formik.getFieldProps('retailMoQ')}
                            name='retailMoQ'
                          />
                          {formik.touched.retailMoQ && formik.errors.retailMoQ && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.retailMoQ}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
              <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className='btn btn-light me-3 border'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                disabled={formik.isSubmitting || isProductLoading}
              >
                Discard
              </button>
              <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h2>Confirmation</h2>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body'>
                      <div className='w-100'>
                        {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                        <div className='fv-row text-center'>
                          <h5>Are you sure you want to discard ?</h5>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        onClick={() => navigate('/catalog/productgroup/list')}
                      >
                        Yes
                      </button>
                      <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-color-modal-action='submit'
                disabled={
                  isProductLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className='indicator-label'>Submit</span>
                {(formik.isSubmitting || isProductLoading) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
              </div>
            </div>



          </div>
        </div>
      </form>

      {(formik.isSubmitting || isProductLoading) && <Loading />}
    </FormikProvider>
  )
}

export { ProductGroupAddPage }