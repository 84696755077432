import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Toast } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import {  ExpenseCategory } from '../core/_models'
import { Result } from '../../../../core/models/Result'
import { PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers'
import { camelize } from '../../../../core/utils/StringHelpers'
import { createExpenseCategory,  updateExpenseCategory } from '../core/_requests'

import { Loading } from '../../../../core/shared/components/Loading'

type Props = {
  isExpenseLoading: boolean
  data: ExpenseCategory | Result
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
 
}

const editStaffSchema = Yup.object().shape({

 
  name: Yup.string()
  .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field")
  .required("Please enter Expense Category Name."),
  description: Yup.string().required('Please enter description.'),
})

const ExpenseCategoryForm: FC<Props> = ({ data, isExpenseLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  let expenseCategory = data as ExpenseCategory;
  const [initialValues] = useState<ExpenseCategory>({
    ...expenseCategory,
    name:expenseCategory.name,   
    description:expenseCategory.description

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editStaffSchema,

    onSubmit: async (expense, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        let result: Result
        if (isNotEmpty(expense.id)) {

          result = await updateExpenseCategory(expense)
        } else {
          result = await createExpenseCategory(expense)
        }
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Expense Category created successfully');
          cancel(true)
        }
        else {
          toast.error('Error Occurred while updating expense Category.');
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
        }
      } catch (ex) {

        toast.error('Error Occurred .');
        console.error(ex)
      }
    },
  })
  
  return (

    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_expensecategory_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column'
          id='kt_modal_add_expensecategory_scroll'
          data-kt-scroll='true'
        >
          <div className='row mb-2'>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>Expense Category Name</label>
              <input
                placeholder='Expense Category Name'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.name && formik.errors.name },
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isExpenseLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
          
          </div>

     
          <div className='row mb-7'>
            <div className="col-12">
            <label className='required fw-bold fs-6 mb-2 ms-1'>Description</label>

<textarea
  rows={3}
  className={clsx(
    'form-control form-control-solid mb-3 mb-lg-0',
    { 'is-invalid': formik.touched.description && formik.errors.description },
    {
      'is-valid': formik.touched.description && !formik.errors.description,
    }
  )}
  disabled={formik.isSubmitting || isExpenseLoading}
  placeholder='Description'
  {...formik.getFieldProps('description')}
  name='description'
/>
{formik.touched.description && formik.errors.description && (
  <div className='fv-plugins-message-container'>
    <div className='fv-help-block'>
      <span role='alert'>{formik.errors.description}</span>
    </div>
  </div>
)}
            </div>

          </div>






          <div className='text-end pt-5'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-regions-modal-action='cancel'
              disabled={formik.isSubmitting || isExpenseLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-regions-modal-action='submit'
              disabled={isExpenseLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isExpenseLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isExpenseLoading) && <Loading />}
    </FormikProvider >
  )
}

export { ExpenseCategoryForm }
