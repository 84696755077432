import { BannerListSearchComponent } from './BannerListSearchComponent'
import { BannerListToolbar } from './BannerListToolbar'
const BannerListHeader = () => {

  return (
    <div className='card-header'>
      <BannerListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <BannerListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default BannerListHeader
