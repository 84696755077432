import { FC } from 'react'
import { useImmer } from 'use-immer';
import { Link, } from 'react-router-dom'

import { FileResult, Result } from '../../../../core/models/Result';

import { Invoice, invoiceItemList, invoicePaymentList, OtherCharges, } from '../core/_models';
import Moment from "moment";
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { saveAs } from 'file-saver';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { json } from 'stream/consumers';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
type Props = {
    data: Result | Invoice;
};



const InvoiceDetailForm: FC<Props> = ({ data }) => {
    const API_URL = process.env.REACT_APP_IMAGE_API_URL
    const PDF_API_URL = process.env.REACT_APP_STORE_API_URL
    const GENERATE_PDF_URL = `${PDF_API_URL}/invoices/downloadpdf`

    const invoiceData = data as Invoice
    let invoiceDate = Moment(invoiceData.invoiceDate);
    let dueDate = Moment(invoiceData.dueDate);
    const initialValues = {
        saleOrderId: invoiceData.saleOrderId,
        invoiceNumber: invoiceData.invoiceNumber,
        invoiceStatusId: invoiceData.invoiceStatusId,
        customerId: invoiceData.customerId,
        customerName: invoiceData.customerName,
        roundOff: invoiceData.roundOff,
        discountApplicable: invoiceData.discountApplicable,
        invoiceDate: invoiceDate.toDate(),
        dueDate: dueDate.toDate(),
        billAddressLine1: invoiceData.billAddressLine1,
        billAddressLine2: invoiceData.billAddressLine2,
        billCityName: invoiceData.billCityName,
        billCountryId: invoiceData.billCountryId,
        billCountryName: invoiceData.billCountryName,
        billStateId: invoiceData.billStateId,
        billStateName: invoiceData.billStateName,
        shipAddressLine1: invoiceData.shipAddressLine1,
        shipAddressLine2: invoiceData.shipAddressLine2,
        shipCityName: invoiceData.shipCityName,
        shipCountryId: invoiceData.shipCountryId,
        shipCountryName: invoiceData.shipCountryName,
        shipStateId: invoiceData.shipStateId,
        shipStateName: invoiceData.shipStateName,
        orderTotal: invoiceData.orderTotal,
        orderSubTotal: invoiceData.orderSubTotal,
        totalDiscountedPrice: invoiceData.totalDiscountedPrice,
        totalTaxAmount: invoiceData.totalTaxAmount,
        totalOtherCharges: invoiceData.totalOtherCharges,
        invoiceStatusName: invoiceData.invoiceStatusName
    }

    let initialSaleOrderItems: invoiceItemList[] = [];
    invoiceData?.itemList.map((item, index) => {
        initialSaleOrderItems.push({
            rowNumber: initialSaleOrderItems.length,
            productId: item.productId,
            productName: item.productName,
            polishingTypeId: item.polishingTypeId,
            polishingTypeName: item.polishingTypeName,
            colorName: item.colorName,
            colorId: item.colorId,
            quantity: item.quantity,
            productPrice: item.productPrice,
            subTotal: item.subTotal,
            discountPercent: item.discountPercent,
            discountAmount: item.discountAmount,
            taxPercent: item.taxPercent,
            taxAmount: item.taxAmount,
            totalInclusiveTax: item.totalInclusiveTax,
            total: item.total,
            isDeleted: item.isDeleted,
            productImagePath: item.productImagePath,

        });
    })

    let initialOtherCharges: OtherCharges[] = [];
    invoiceData.otherCharges.map((item) => {
        initialOtherCharges.push({
            rowNumber: initialOtherCharges.length,
            id: item.id,
            value: item.value,
            label: item.label,
            sign: item.sign,
            isDeleted: item.isDeleted
        })
    })

    let initialPaymentList: invoicePaymentList[] = [];
    invoiceData?.paymentList?.map((item) => {
        initialPaymentList.push({
            invoicePaymentId: item.invoicePaymentId,
            amountReceived: item.amountReceived,
            paymentDate: item.paymentDate,
            paymentModeId: item.paymentModeId,
            paymentModeName: item.paymentModeName,
            referenceNumber: item.referenceNumber,
            notes: item.notes,
            isDeleted: item.isDeleted,
            rowNumber: initialPaymentList.length
        })
    })

    const [otherCharges, setOtherCharges] = useImmer<OtherCharges[]>(initialOtherCharges)
    const [saleOrderItems, updateSaleOrderItems] = useImmer<invoiceItemList[]>(initialSaleOrderItems);
    const [paymentDetails, setPaymentDetails] = useImmer<invoicePaymentList[]>(initialPaymentList)
    let totalAmount: number = 0;
    let totalTaxAmount: number = 0;
    totalAmount = (initialValues?.orderSubTotal as number - (initialValues?.totalDiscountedPrice as number))
    totalTaxAmount = totalAmount + (initialValues.totalTaxAmount as number)


    const GenerateOrderFormPdf = async (id: number): Promise<any | FileResult> => {

        return await axios
            .get(`${GENERATE_PDF_URL}/${id}`, {
                responseType: 'blob'
            })
            .then(async (response: any) => {
                if(response.type == 'application/json'){
                    const jsonData = await response.text();
                    const errorData = JSON.parse(jsonData);
                    return errorData
                }
                else {
                    let filename = response.headers['content-disposition']
                    .split(';')
                    .find((n: any) => n.includes('filename='))
                    .replace('filename=', '')
                    .trim();

                var result: FileResult = {
                    data: response.data,
                    name: filename
                }
                return result
                }             
                
            })
         
            .catch((err: any) => {
                return err
               

            })

    }

    const invoicepdf = (saleorderId) => {
        GenerateOrderFormPdf(saleorderId).then(file => {
            let output = file as FileResult;
            if (output.data) {
                let url = window.URL
                    .createObjectURL(output.data);
                saveAs(url, output.name);
            }
            else {
                toast.error(file.exception)
            }
        });

    }


    return (
        <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <div id="kt_app_content_container" className="app-container  container-xxl ">

                <div className="d-flex flex-column gap-7 gap-lg-7">
                    <KTCard>
                        <KTCardBody>
                        <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">

<ul
    className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto"
    role="tablist"
>

    <li className="nav-item" role="presentation">
        <a
            className="nav-link text-active-primary pb-4 active"
            data-bs-toggle="tab"
            href="#kt_ecommerce_sales_order_summary"
            aria-selected="true"
            role="tab"
        >
            Order Summary
        </a>
    </li>



    <li className="nav-item" role="presentation">
        <a
            className="nav-link text-active-primary pb-4"
            data-bs-toggle="tab"
            href="#kt_ecommerce_sales_payment_history"
            aria-selected="false"
            role="tab"
            tabIndex={-1}
        >
            Payment History
        </a>
    </li>

</ul>

<Link
    to={`/sales/invoice/list`}
    className="btn btn-primary btn-sm ms-auto"
>
    <i className="ki-duotone ki-left fs-2" />{"Back"}
</Link>


</div>
                        </KTCardBody>
                    </KTCard>
                    

                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">

                        <div className="card flex-row-fluid">

                            <div className="card-header">
                                <div className="card-title">
                                    <h3>Invoice Details (#{initialValues.invoiceNumber})</h3>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="table-responsive">

                                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                        <tbody className="fw-semibold text-gray-600">
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ki-duotone ki-calendar fs-2 me-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                        </i>{" "}
                                                        Date Added
                                                    </div>
                                                </td>
                                                <td className="fw-bold text-end">{format(initialValues.invoiceDate, 'MM/dd/yyyy')}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ki-duotone ki-wallet fs-2 me-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                            <span className="path3" />
                                                            <span className="path4" />
                                                        </i>{" "}
                                                        Invoice Status
                                                    </div>
                                                </td>
                                                <td className="fw-bold text-end">
                                                    {initialValues.invoiceStatusName}

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>

                        <div className="card flex-row-fluid">

                            <div className="card-header">
                                <div className="card-title">
                                    <h3>Customer Details</h3>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="table-responsive">

                                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                        <tbody className="fw-semibold text-gray-600">
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ki-duotone ki-profile-circle fs-2 me-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                            <span className="path3" />
                                                        </i>{" "}
                                                        Customer
                                                    </div>
                                                </td>
                                                <td className="fw-bold text-end">
                                                    <div className="d-flex align-items-center justify-content-end">

                                                        <label

                                                            className="text-gray-600 text-hover-primary"
                                                        >
                                                            {initialValues.customerName}
                                                        </label>

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ki-duotone ki-sms fs-2 me-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                        </i>{" "}
                                                        Email
                                                    </div>
                                                </td>
                                                <td className="fw-bold text-end">
                                                    <a
                                                        href=""
                                                        className="text-gray-600 text-hover-primary"
                                                    >
                                                        {/* {initialValues.email} */}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ki-duotone ki-phone fs-2 me-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                        </i>{" "}
                                                        Phone
                                                    </div>
                                                </td>
                                                {/* <td className="fw-bold text-end">{initialValues.contactNumber}</td> */}
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        <div className="card flex-row-fluid">

                            <div className="card-header">
                                <div className="card-title">
                                    <h3>Documents</h3>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="table-responsive">

                                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                        <tbody className="fw-semibold text-gray-600">
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        <i className="ki-duotone ki-devices fs-2 me-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                            <span className="path3" />
                                                            <span className="path4" />
                                                            <span className="path5" />
                                                        </i>{" "}
                                                        Download Invoice

                                                    </div>
                                                </td>
                                                <td className="fw-bold">
                                                    <Button
                                                        className="btn btn-primary btn-sm me-lg-n7"
                                                        onClick={() => invoicepdf(invoiceData.id)}
                                                    >
                                                        Download
                                                    </Button>

                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="tab-content">

                        <div
                            className="tab-pane fade active show"
                            id="kt_ecommerce_sales_order_summary"
                            role="tab-panel"
                        >

                            <div className="d-flex flex-column gap-7 gap-lg-7">
                                <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-7">

                                    <div className="card flex-row-fluid position-relative">

                                        <div className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                                            <i
                                                className="ki-solid ki-two-credit-cart"
                                                style={{ fontSize: "14em" }}
                                            ></i>
                                        </div>

                                        <div className="card-header">
                                            <div className="card-title">
                                                <h3>Billing Address</h3>
                                            </div>
                                        </div>

                                        {initialValues.billAddressLine1 || initialValues.billAddressLine2 || initialValues.billStateName || initialValues.billCityName ?
                                            <div className="card-body">
                                                {initialValues.billAddressLine1}, {initialValues.billAddressLine2}
                                                <br />
                                                {initialValues.billStateName}
                                                <br />
                                                {initialValues.billCityName}
                                                <br />


                                            </div>
                                            :
                                            <div className="card-body pt-0"><h3>No Bill Address Available</h3></div>
                                        }
                                    </div>

                                    <div className="card flex-row-fluid position-relative">

                                        <div className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                                            <i
                                                className="ki-solid ki-delivery"
                                                style={{ fontSize: "13em" }}
                                            ></i>
                                        </div>

                                        <div className="card-header">
                                            <div className="card-title">
                                                <h3>Shipping Address</h3>
                                            </div>
                                        </div>
                                        {initialValues.shipAddressLine1 || initialValues.shipAddressLine2 || initialValues.shipStateName || initialValues.shipCityName ?

                                            <div className="card-body">
                                                {initialValues.shipAddressLine1},{initialValues.shipAddressLine2}
                                                <br />
                                                {initialValues.shipStateName}
                                                <br />
                                                {initialValues.shipCityName}
                                                <br />

                                            </div>
                                            :
                                            <div className="card-body">
                                                <h3>No Ship Address Available</h3>
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="card flex-row-fluid overflow-hidden">

                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>Order #{initialValues.saleOrderId}</h3>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">

                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                <thead>
                                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                        <th className="min-w-175px">Product</th>
                                                        <th className="min-w-70px text-end">Qty</th>
                                                        <th className="min-w-100px text-end">Unit Price</th>
                                                        <th className="min-w-100px text-end"> Discount </th>
                                                        <th className="min-w-100px text-end"> Tax Amount</th>
                                                        <th className="min-w-100px text-end">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="fw-semibold text-gray-600">
                                                    {saleOrderItems.filter(t => !t.isDeleted).map((soitem, index) => (
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">

                                                                    <Link
                                                                        to={""}
                                                                        className="symbol symbol-50px"
                                                                    >
                                                                        <span
                                                                            className="symbol-label"
                                                                        >
                                                                            {soitem.productImagePath !== null ?
                                                                                <img className="symbol-label" src={`${API_URL}/${soitem?.productImagePath}`} alt={soitem.productName} />
                                                                                :
                                                                                <img className="symbol-label" src={'media/svg/files/blank-image.svg'} alt={soitem.productName} />
                                                                            }
                                                                        </span>
                                                                    </Link>

                                                                    <div className="ms-5">

                                                                        <Link
                                                                            to={''}
                                                                            className="fw-bold text-gray-600 text-hover-primary"
                                                                        >
                                                                            {soitem.productName}
                                                                        </Link>
                                                                        <div className="fs-7 text-muted">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">{soitem.quantity} </td>
                                                            <td className="text-end">{formatCurrencyInINR(soitem.productPrice)}</td>
                                                            <td className="text-end">
                                                                {soitem?.discountPercent as number > 0 ? <> <span>{formatCurrencyInINR(soitem.discountAmount)} </span>
                                                                    <span>({soitem.discountPercent}% )</span></> :
                                                                    <p>
                                                                        {formatCurrencyInINR(soitem.discountAmount)}
                                                                        {/* '₹ 0.00' */}
                                                                    </p>}

                                                            </td>
                                                            <td className="text-end">
                                                                {soitem?.taxPercent as number > 0 ? <>

                                                                    <span>{formatCurrencyInINR(soitem.taxAmount)}  </span>
                                                                    <span>({soitem.taxPercent}% )</span></> :
                                                                    <p> {formatCurrencyInINR(soitem.taxAmount)}</p>
                                                                }
                                                            </td>
                                                            <td className="text-end">{soitem.taxPercent === null ? formatCurrencyInINR(soitem.total) : formatCurrencyInINR(soitem.totalInclusiveTax)}</td>
                                                        </tr>
                                                    ))}
                                                    <tr className="text-end text-gray-800">

                                                        <td colSpan={2} className="text-start">SubTotal</td>
                                                        <td className="text-end text-gray-800">{formatCurrencyInINR(initialValues.orderSubTotal)}</td>
                                                        <td className="text-end">{initialValues.totalDiscountedPrice ? formatCurrencyInINR(initialValues.totalDiscountedPrice) : '₹ 0.00'}</td>
                                                        <td className="text-end">{initialValues.totalTaxAmount ? formatCurrencyInINR(initialValues.totalTaxAmount) : '₹ 0.00'}</td>
                                                        {initialValues?.totalTaxAmount === null ?
                                                            <td className="text-end text-gray-800">{formatCurrencyInINR(totalAmount)}</td>
                                                            :
                                                            <td className="text-end text-gray-800">{formatCurrencyInINR(totalTaxAmount)}</td>
                                                        }

                                                    </tr >

                                                    {/* <tr>
                                                <td colSpan={5} className="text-end text-gray-800">
                                                    Subtotal
                                                </td>
                                                <td className="text-end text-gray-800">{formatCurrencyInINR(initialValues.subTotal)}</td>
                                            </tr> */}


                                                    {otherCharges.filter(t => !t.isDeleted).map((ocitem, index) => (
                                                        <tr key={ocitem.rowNumber}>
                                                            <td colSpan={5} className="text-end">{ocitem.label}({ocitem.sign === 1 ? '+' : '-'})</td>
                                                            <td className="text-end">{formatCurrencyInINR(ocitem.value)}</td>
                                                        </tr>
                                                    ))}
                                                    {/* <tr>
                                                <td colSpan={5} className="text-end">
                                                    Discount Amount
                                                </td>
                                                <td className="text-end">{initialValues.totalDiscountedPrice ? formatCurrencyInINR(initialValues.totalDiscountedPrice) : '₹ 0.00'}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={5} className="text-end">
                                                    Tax Amount
                                                </td>
                                                <td className="text-end">{initialValues.totalTaxAmount ? formatCurrencyInINR(initialValues.totalTaxAmount) : '₹ 0.00'}</td>
                                            </tr> */}
                                                    <tr>
                                                        <td colSpan={5} className="text-end">
                                                            Round Off
                                                        </td>
                                                        <td className="text-end">{initialValues.roundOff?.toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} className="fs-3 text-gray-900 text-end">
                                                            Grand Total
                                                        </td>
                                                        <td className="text-gray-900 fs-3 fw-bolder text-end">
                                                            {formatCurrencyInINR(initialValues.orderTotal)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>



                        <div
                            className="tab-pane fade"
                            id="kt_ecommerce_sales_payment_history"
                            role="tab-panel"
                        >
                            <div className="d-flex flex-column gap-7 gap-lg-7">
                                <div className="card flex-row-fluid">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>Payment History</h3>
                                        </div>
                                    </div>

                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                <thead>
                                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                        {/* <th className="min-w-175px">Payment Number</th> */}
                                                        <th className="min-w-100px">Payment Date </th>
                                                        <th className="min-w-70px">Payment Mode </th>
                                                        {/* <th className="min-w-70px">Order Amount </th> */}
                                                        <th className="min-w-70px">Amount Received</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="fw-semibold text-gray-600">
                                                    {paymentDetails?.length > 0 ? <>
                                                        {paymentDetails?.map((payment: invoicePaymentList, index: number) => (

                                                            <tr key={index}>
                                                                {/* <td>{payment.paymentNumber}</td> */}
                                                                <td>{format(new Date(payment?.paymentDate as Date), 'dd/MM/yyyy')}</td>
                                                                <td>{payment.paymentModeName} </td>
                                                                {/* <td>{formatCurrencyInINR(payment.orderAmount)}</td> */}
                                                                <td>{formatCurrencyInINR(payment.amountReceived)}</td>
                                                            </tr>

                                                        ))}
                                                    </> :
                                                        <span className=' '><h3 className='text-danger mt-5'>No Record found</h3></span>}
                                                </tbody>
                                            </table>
                                            {/*end::Table*/}
                                        </div>
                                    </div>
                                    {/*end::Card body*/}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export { InvoiceDetailForm }
